<template>
  <div>
     <Header :currentIndex="currentIndex" :navStyle="navStyle"></Header>
    <div class="wrap">
      <div class="us_content">
      <!-- <div class="usercenter_header">
        <div class="usercenter_header_inset">
         
        </div>
      </div> -->

      <div class="usercenter_content flex-wrap-st justify-btw">
        <ul class="usercenter_content_left">
          <li
            @click="changeTab(0)"
            :class="currentNavIndex == 0 ? 'selected' : ''"
          >
            个人主页 <label></label>
          </li>
          <li
            @click="changeTab(1)"
            :class="currentNavIndex == 1 ? 'selected' : ''"
          >
            账号安全 <label></label>
          </li>
        </ul>
        <div class="usercenter_content_right" v-if="currentNavIndex == 0">
          <div class="user_info_item">
            <div class="user_name flex-wrap-st align-fed">
              <div class="user_name_txt">
                <div v-if="!editStatus">
                  {{ userData.user_name }}
                </div>
                <div v-else>
                  <el-input
                    v-model="userData.user_name"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <div class="edit_icon" @click="editUserName" v-if="!editStatus">
                <i class="el-icon-edit"></i>
              </div>
              <div class="edit_icon" v-else>
                <el-button  @click="saveUserName" type="primary" size="small">保存</el-button>
                <!-- <el-button  @click="saveUserName" type="text" size="small">取消</el-button> -->
                <el-button @click="cancelSaveUserName" type="info" size="small">取消</el-button>
                

              </div>
            </div>
            <p class="user_id">登录账号: {{ userData.mobile }}</p>
          </div>
          <div class="user_order_list">
            <h5 class="user_order_list_title">
              已购文章
              <label></label>
            </h5>
            <el-table :data="myArticleList.data" style="width: 100%" @row-click='goNewsDetail'>
              <el-table-column prop="title" label="精选报告标题" width="280">
              </el-table-column>

              <el-table-column prop="published" label="订阅时间" width="280">
              </el-table-column>

              <el-table-column prop="price" label="金额"> </el-table-column>
            </el-table>
            <div class="flex-wrap-st justify-ctc" style="margin-top: 77px">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="myArticleList.total"
                @prev-click="prevClick"
                @next-click="nextClick"
                @size-change="sizeChange"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="usercenter_account_control" v-else>
          <h4 class="usercenter_account_title">账号安全</h4>
          <p class="usercenter_account_username">登录账号{{ userData.mobile }}</p>

          <div class="control_item_wrap">
            <div class="control_item flex-wrap-st align-ctc justify-btw">
              <div class="flex-wrap-st align-ctc">
                <div class="control_item_icon">
                  <img src="@/assets//phone_icon.png" alt="" />
                </div>

                <div class="flex-wrap-st align-ctc control_item_txt">
                  <label>绑定手机号</label>
                  <p>绑定手机：{{userData.mobile}}，若手机丢失或停用，请及时更换。</p>
                </div>
              </div>

              <div class="change_phone" @click="changePhone">更换手机号</div>
            </div>
            <div class="control_item flex-wrap-st align-ctc justify-btw">
              <div class="flex-wrap-st align-ctc">
                <div class="control_item_icon">
                  <img src="@/assets//password_icon.png" alt="" />
                </div>

                <div class="flex-wrap-st align-ctc control_item_txt">
                  <label>登陆密码</label>
                  <p>建议您定期更换密码，可以使账号更安全</p>
                </div>
              </div>

              <div class="change_phone" @click="changePassword">修改密码</div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <el-dialog
      title="更换手机号"
      :visible.sync="changePhoneVisible"
      :close-on-click-modal='false'
      
      width="490px"
      center
      destroy-on-close
      @closed="resetChangephoneStatus"
    >
      <div>
        <div>
          <div v-if="changePhoneIndex == 0">
            <label>已绑定的手机号:</label>{{ userData.mobile }}
            <div class="flex-wrap-st old_phone_input_wrap align-ctc">
              <el-input
                style="width: 100%"
                placeholder="请输入验证码"
                v-model="captchaN"
                ref="captcha"
                clearable
              >
              </el-input>
              <div class="getCode"  @click="getCaptcha"  v-show="showCount">  
                <label v-show="sendNum == 0">获取验证码</label>
                <label v-show="sendNum == 1">重新发送</label>
              </div>
              <div class="getCode" v-show="!showCount" >  
                {{ count }}秒后重新试
              </div>
            </div>

            <div class="next_step" @click="nextStep">下一步</div>
            <div class="tips">
              <p>温馨提示</p>
              <ul>
                <li>为保障您的账号安全, 变更中心信息需要身份验证</li>
                <li>若有疑问请联系在线客服或拨打400-xxxx-xxx（9:00-22:00）</li>
              </ul>
            </div>
          </div>

          <div v-if="changePhoneIndex == 1">
            <label>请输入要绑定的新号码</label>
            <el-input
              style="margin-top: 21px"
              placeholder="请输入手机号"
              v-model="new_phone"
              ref='new_phone'
              clearable
            >
            </el-input>
            <div class="flex-wrap-st old_phone_input_wrap align-ctc">
              <el-input
                style="width: 100%"
                placeholder="请输入短信验证码"
                v-model="step2_captcha"
                clearable
              >
              </el-input>
              <div class="getCode"  @click="getCaptchaStep2"  v-show="showCount2">  
                <label v-show="sendNum2 == 0">获取验证码</label>
                <label v-show="sendNum2 == 1">重新发送</label>
              </div>
              <div class="getCode" v-show="!showCount2" >  
                {{ count2 }}秒后重新试
              </div>
            </div>

            <div class="next_step" @click="compleChangePhone">完成</div>
            <div class="tips">
              <p>温馨提示</p>
              <ul>
                <li>为保障您的账号安全, 变更中心信息需要身份验证</li>
                <li>若有疑问请联系在线客服或拨打400-xxxx-xxx（9:00-22:00）</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="修改登录密码"
      :visible.sync="changePasswordVisible"
      width="490px"
      :close-on-click-modal='false'
      center
      destroy-on-close
      @closed="resetChangephoneStatus"
    >
      <div>
        <div>
          <div v-if="changePhoneIndex == 0">
            <label>已绑定的手机号:</label>{{userData.mobile}}
            <div class="flex-wrap-st old_phone_input_wrap align-ctc">
              <el-input
                style="width: 100%"
                placeholder="请输入验证码"
                v-model="captchaN"
                ref='captcha'
                clearable
              >
              </el-input>

             
              <div class="getCode"  @click="getCaptcha"  v-show="showCount">  
                <label v-show="sendNum == 0">获取验证码</label>
                <label v-show="sendNum == 1">重新发送</label>
              </div>
              <div class="getCode" v-show="!showCount" >  
                {{ count }}秒后重新试
              </div>

            </div>

            <div class="next_step" @click="nextStep">下一步</div>
            <div class="tips">
              <p>温馨提示</p>
              <ul>
                <li>为保障您的账号安全, 变更中心信息需要身份验证</li>
                <li>若有疑问请联系在线客服或拨打400-xxxx-xxx（9:00-22:00）</li>
              </ul>
            </div>
          </div>

          <div v-if="changePhoneIndex == 1">
            <label>请输入新密码</label>
            <el-input
              style="margin-top: 21px"
              placeholder="设置6-20位登录密码"
              v-model="new_password"
              type='password'
              clearable
            >
            </el-input>
            <el-input
              style="margin-top: 21px"
              placeholder="重复设置6-20位登录密码"
              v-model="confrim_new_password"
              clearable
              type='password'
            >
            </el-input>

            <div class="next_step" @click="editPassword">确定</div>
            <div class="tips">
              <p>温馨提示</p>
              <ul>
                <li>为保障您的账号安全, 变更中心信息需要身份验证</li>
                <li>若有疑问请联系在线客服或拨打400-xxxx-xxx（9:00-22:00）</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from "../components/footer";
import Header from "../components/header";
import { userInfo } from "@/api/userInfo";
import { articleList } from "@/api/article";
import { changeName } from "@/api/changeName";
import { captcha } from '@/api/captcha'
import { verifyPhone, passwordReset } from "@/api/resetpassword";
import { changeMobile } from '@/api/changePhone'
import { getToken, removeToken } from "@/utils/auth"; // get token from cookie

import { logout } from '@/api/logout'


export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      sendNum: 0,
      showCount: true,
      errorStatus: false,
      count: "",
      warningTxt: "",
      timer: null,





      sendNum2: 0,
      showCount2: true,
      count2: "",
      timer2: "",
      old_phone: "",
      captchaN: '',
      new_password: '',
      confrim_new_password: '',
      currentIndex: 5,
      myArticleList: [],
      editStatus: false,
      currentNavIndex: 0,
      navStyle: "blacks",
      userData: {},
      changePhoneIndex: 0,
      new_phone: "",
      step2_captcha: "",
      captcha: "",
      listQuery: {
        page: 1,
        limit: 10,
      },
      changePasswordVisible: false,
      changePhoneVisible: false,

      tableData: [],
    };
  },
  methods: {
      
      goNewsDetail(e) {
          
          this.$router.push({
              path: '/newsDetail',
              query: {
                  id: e.id
              }
          })
      },
      passwordResetFc(t1, t2) {
      passwordReset(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            // this.active++
            // this.active = 3;
            this.changePasswordVisible = false
            this.$message.success(data.message)
            let self = this
            setTimeout(function(){
                self.goLoginOut()
            },700)
          } else {
               this.$message.error(data.message)
          }
        }
      });
    },
    goLoginOut() {
          logout().then(res => {
              if (res.data.code === 200) {
                  let data =res.data.data
           
                  if (data.error === 0) {
                      sessionStorage.removeItem('userinfo')
                      removeToken()

                     this.$router.replace('/index')
                  }
              }
          })
      },
      editPassword() {
          if(this.new_password.length !== 0 && this.confrim_new_password.length!==0) {
              if (this.new_password !== this.confrim_new_password) {
                  this.$message.error('两次输入的密码不一致，请检查')
              } else {
                this.passwordResetFc(this.userData.mobile, this.confrim_new_password);
              }
          } else {
              this.$message.error('密码不能为空')
          }
      },
      changePhone() {

      },
    getCaptcha() {
        captcha(this.userData.mobile, "", 3).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
              this.countFc();
            } else {
              this.$message.error(data.message);
            }
          }
        });
     
    },
    getCaptchaStep2() {
      if (this.new_phone !== '') {
           captcha(this.new_phone ,"", 3).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
              this.countFc2();
            } else {
              this.$message.error(data.message);
            }
          }
        });
      } else {
          this.$message.error('请输入要绑定的新号码');
          this.$refs['new_phone'].focus()
      }
    },
    verifyPhoneFc(t1, t2) {
      verifyPhone(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.changePhoneIndex = 1;
          } else {
            this.$refs["captcha"].focus();
            this.$message.error(data.message);
          }
        }
      });
    },
    changeMobileFc(t1) {
        changeMobile(t1).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.changePhoneVisible=false
            this.getUserInfo()
          } else {
            
            this.$message.error(data.message);
          }
        } 
        })
    },
    
    compleChangePhone(t1, t2){
        if(this.new_phone == '' || this.step2_captcha == '') {
            this.$message.error('请完善需要更换的信息')
        } else {
            verifyPhone(this.new_phone, this.step2_captcha).then((res) => {
                if (res.data.code == 200) {
                let data = res.data.data;
                if (data.error == 0) {
                this.changeMobileFc(this.new_phone)
                } else {
                    this.$refs["step2_captcha"].focus();
                    this.$message.error(data.message);
                }
                }
            });
        }
     
    },
    countFc() {
      this.sendNum = this.sendNum + 1;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCount = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCount = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    countFc2() {
      this.sendNum2 = this.sendNum2 + 1;
      const TIME_COUNT = 60;
      if (!this.timer2) {
        this.count2 = TIME_COUNT;
        this.showCount2 = false;
        this.timer2 = setInterval(() => {
          if (this.count2 > 0 && this.count2 <= TIME_COUNT) {
            this.count2--;
          } else {
            this.showCount2 = true;
            clearInterval(this.timer2);
            this.timer2 = null;
          }
        }, 1000);
      }
    },

    getUserInfo() {
      userInfo().then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;
          this.userData = data.data;
        }
      });
    },
    getArticleListFc(t1, t2) {
      articleList(t1, t2).then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;

          if (data.error == 0) {
            this.myArticleList = data.data;
            console.log(data.data.data);
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    saveUserName() {
      changeName(this.userData.user_name).then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.editStatus = false;
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    cancelSaveUserName(){
      this.editStatus = false
    },
    editUserName() {
      this.editStatus = true;
    },
    changeTab(e) {
      this.currentNavIndex = e;
    },
    handleClick() {},
    changePhone() {
      this.changePhoneVisible = true;
    },
    changePassword() {
      this.changePasswordVisible = true;
    },
    nextStep() {
      if (this.captchaN !== '') {
        this.verifyPhoneFc(this.userData.mobile, this.captchaN)
        }
        else{
            this.$message.error('请输入验证码')
        }
    },
    resetChangephoneStatus() {
      this.changePhoneIndex = 0;
      this.captchaN = ''
      this.new_password = ''
      this.confrim_new_password = ''

      this.sendNum = 0
      this.showCount = true,
      this.count = ''
      this.timer = null


      this.sendNum2 = 0
      this.showCount2 = true,
      this.count2 = ''
      this.timer2 = null





    },
    prevClick() {
      if (this.listQuery.page !== 0) {
        this.listQuery.page--;
        this.getArticleListFc(this.listQuery.page, this.listQuery.limit);
      }
    },
    nextClick() {
      this.listQuery.page++;
      this.getArticleListFc(this.listQuery.page, this.listQuery.limit);
    },
    sizeChange(e) {
      console.log(e);
    },
    currentChange(e) {
      this.getArticleListFc(e, this.listQuery.limit);
    },
  },
  created() {
    this.getUserInfo();
    this.getArticleListFc(this.listQuery.page, this.listQuery.limit);
  },
};
</script>

<style scoped>
.getCode label{
    cursor: pointer;
}
.wrap {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9f9f9;
  z-index: 0;
  overflow: auto;
}
.us_content{
  min-width: 1200px;

}
.usercenter_header {
  width: 100%;
  height: 80px;
  background: #ffffff;
}
.usercenter_header {
  width: 100%;
  height: 80px;
  box-shadow: 0 3px 6px rgba(125, 125, 125, 0.2);
}
.usercenter_header_inset {

  margin: auto;
}
.usercenter_header .index_content_header_nav a {
  color: #333333;
}
.usercenter_header .index_content_header_login_wrap label {
  background: #dfdfdf;
}
.usercenter_content {
  width: 1200px;
  margin: auto;
  margin-top: 28px;

  height: auto;
  margin-bottom: 28px;
}
.usercenter_content_left {
  width: 168px;
  min-height: 808px;
  background: #ffffff;
  flex-shrink: 0;
  margin: 0;
}
.getCode label{
    cursor: pointer;
}
.usercenter_content_right {
  width: 1012px;
  height: auto;
  flex-shrink: 0;
}
.usercenter_content_left {
  padding: 67px 0 0 0;
}
.usercenter_content_left li {
  list-style: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #333333;
  height: 40px;
  line-height: 40px;
  position: relative;
  cursor: pointer;
}
.usercenter_content_left li label {
  width: 4px;
  background: #fdd000;
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}
.usercenter_content_left li.selected {
  background: rgba(253, 208, 0, 0.1);
  cursor: pointer;
}
.selected label {
  display: block !important;
}
.user_info_item {
  padding: 33px 48px;
  background: #ffffff;
  margin-bottom: 21px;
}
.user_name {
  margin-bottom: 8px;
}
.user_name label {
  width: 16px;
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}
.user_name label img {
  width: 100%;
  display: block;
}
.user_name span {
  font-size: 24px;
  color: #333333;
}
.user_id {
  font-size: 14px;
  color: #999999;
  margin: 0;
}
.user_order_list {
  background: #ffffff;
  padding: 42px 48px;
}
.user_order_list_title {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 7px;
  font-size: 16px;
  color: #212121;
  font-weight: normal;
  position: relative;
  margin-bottom: 36px;
}
.user_order_list_title label {
  width: 30px;
  height: 2px;
  background: #fdd000;
  position: absolute;
  left: 0;
  bottom: -1px;
  display: inline-block;
}
.user_order_list table {
  border: 1px solid #ededed;
  width: 100%;
  margin-top: 36px;
}
.user_order_list table th {
  background: #f9f9f9;
  height: 48px;
  padding-left: 36px;
}
.user_order_list table tr {
  border-bottom: 1px solid #ededed;
  height: 55px;
}
.user_order_list table tr td {
  padding-left: 36px;
  font-size: 14px;
  color: #333333;
}
.table_control {
  color: #4393F9 !important;
}
.table_control label {
  cursor: pointer;
}
.usercenter_account_control {
  padding: 33px 48px;
  background: #ffffff;
  width: 1012px;
}
.usercenter_account_title {
  font-size: 24px;
  color: #333333;
  margin: 0;
  font-weight: normal;
  margin-bottom: 8px;
}
.usercenter_account_username {
  font-size: 14px;
  color: #999999;
}
.control_item_wrap {
  margin-top: 27px;
}
.control_item {
  width: 100%;
  background: #ffffff;
  padding: 32px;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-bottom: 21px;
}
.control_item p {
  margin: 0;
  padding: 0;
}
.control_item_icon {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  margin-right: 18px;
}
.control_item_icon img {
  width: 100%;
  display: block;
}
.control_item_txt label {
  color: #333333;
  font-size: 16px;
}
.control_item_txt p {
  font-size: 12px;
  color: #666666;
  margin-left: 18px;
}
.change_phone {
  font-size: 14px;
  color: #4a90e2;
  cursor: pointer;
}
.getCode {
  width: 118px;
  height: 40px;
  background: #fdd000;
  border-radius: 4px;
  font-size: 14px;
  color: #333333;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.getCode label{
    cursor: pointer;
}
.old_phone_input_wrap {
  margin-top: 21px;
}
.next_step {
  width: 100%;
  height: 40px;
  background: #fdd000;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #333333;
  margin-top: 48px;
  border-radius: 4px;
  cursor: pointer;
}
.tips {
  margin: 0;
  margin-top: 70px;
  color: #999999;
  font-size: 14px;
}
.tips ul {
  margin-left: 18px;
}
.tips ul li {
  line-height: 180%;
}
.tips p {
  margin: 0;
  margin-bottom: 15px;
}

.aboutus_content_right {
  background: #ffffff;
  height: auto;
  width: 1012px;
  padding: 39px 68px;
}
.aboutus_content_xy {
  background: #ffffff;
  min-height: 808px;
  width: 1012px;
  padding: 39px 68px;
}
.aboutus_content_right {
  font-size: 14px;
  color: #666666;
}
.aboutus_content_right h6 {
  font-size: 14px;
  color: #333333;
  margin: 0;
  padding: 10px 0;
}
.conact_us_item h4 {
  font-size: 14px;
  color: #333333;
  margin: 0;
  margin-bottom: 15px;
}
.conact_us_item ul {
  margin: 0;
  padding: 0;
}
.conact_us_item ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #333333;
  line-height: 180%;
}
.conact_us_item {
  margin-bottom: 40px;
}
.edit_icon {
  cursor: pointer;
  margin-left: 15px;
  font-size: 18px;
  margin-bottom: 3px;
}
.user_name_txt {
  font-size: 26px;
}
.btx {
  font-size: 14px;
}
</style>