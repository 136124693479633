

import request from '../utils/request'

export function changeName (t1) {
    const data = {
        user_name: t1
        
    }
    return request({
      url: `/website/user/changeName`,
      method: 'post',
      data
    })
  }
  