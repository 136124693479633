


import request from '../utils/request'

export function articleList (t1, t2) {
    return request({
      url: `/website/article/myArticle?page=${t1}&limit=${t2}`,
      method: 'post'
    })
  }
