


import request from '../utils/request'

export function changeMobile (t1) {
    const data = {
        mobile: t1
    }
    return request({
      url: `/website/user/changeMobile`,
      method: 'post',
      data
    })
  }
  