<template>
  <div class="body_login_wrapper flex-wrap-st justify-ctc align-ctc">

    <router-link to='/index' class="login_logo">
        <img src="@/assets/login_logo.png" alt="">
    </router-link>
    <div>
      <div class="body_login_form">
        <h1 class="body_login_title">重置密码</h1>
        <div class="progress_bar">
          <el-steps :active="active" align-center finish-status="success">
            <el-step title="进行中"></el-step>
            <el-step title="设置新密码"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </div>
        <div v-show="active == 0">
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusUserNameStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_phone.png" alt="" />
              </div>
              <input
                type="text"
                @focus="userNameInputFocus"
                ref="mobile"
                @blur="userNameInputBlur"
                v-model="ruleForm.mobile"
                maxlength="11"
                placeholder="请输入手机号码"
              />
              <div
                v-if="ruleForm.mobile !== ''"
                @click="clearInput('mobile')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusCatpchaStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_captcha.png" alt="" />
              </div>
              <input
                type="number"
                @focus="captchaInputFocus"
                ref="captcha"
                @blur="captchaInputBlur"
                v-model="ruleForm.captcha"
                maxlength="6"
                placeholder="请输入验证码"
              />
              <div
                style="right: 70px"
                v-if="ruleForm.captcha !== ''"
                @click="clearInput('captcha')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>

              <div class="sendCaptcha" v-show="showCount" @click="sendCaptcha">
                <label v-show="sendNum == 0">发送验证码</label>
                <label v-show="sendNum == 1">重新发送</label>
              </div>
              <div
                class="sendCaptcha"
                v-show="!showCount"
                style="color: #9b9b9b; font-size: 14px"
              >
                {{ count }}秒后重新试
              </div>
            </div>
          </div>
        </div>

        <div v-show="active == 1">
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusnewpasswordStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_phone.png" alt="" />
              </div>
              <input
                type="password"
                @focus="newpasswordInputFocus"
                ref="newpassword"
                @blur="newpasswordInputBlur"
                v-model="ruleForm.newpassword"
                maxlength="20"
                placeholder="请设置6至20位登录密码"
              />
              <div
                v-if="ruleForm.newpassword !== ''"
                @click="clearInput('newpassword')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusconfirmpasswordStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset_icon">
              <img src="@/assets/register_phone.png" alt="" />
            </div>
            <input
              type="password"
              @focus="confirmpasswordInputFocus"
              ref="confirmpassword"
              @blur="confirmpasswordInputBlur"
              v-model="ruleForm.confirmpassword"
              maxlength="20"
              placeholder="请再次输入登录密码"
            />
            <div
              v-if="ruleForm.confirmpassword !== ''"
              @click="clearInput('confirmpassword')"
              class="login_input_item_clear"
            >
              <img src="@/assets/close_btn.png" alt="" />
            </div>
          </div>
        </div>

        <div v-show="active == 3">
          <div class="success_status">
            <div class="success_status_icon">
              <img src="@/assets/successStatus.png" alt="" />
            </div>
            <!-- <div class="success_ststus_title">密码重置成功</div> -->
            <div class="success_status_txt">请妥善保管您的账户信息</div>
          </div>
        </div>
        <div class="error_message flex-wrap-st align-ctc" v-show="errorStatus">
          <img src="@/assets/error_message_icon.png" alt="" />
          {{ warningTxt }}
        </div>
        <div class="login_button_wrap">
          <!-- <button disabled>注册</button> -->
          <el-button
            v-if="active !== 3"
            @click="registerFc"
            class="register_button"
            type="warning"
            :disabled="
              ruleForm.mobile !== '' && ruleForm.captcha !== '' ? false : true
            "
            >下一步</el-button
          >
          <el-button
            v-else
            @click="registerFc"
            class="register_button"
            type="warning"
            :disabled="
              ruleForm.mobile !== '' && ruleForm.captcha !== '' ? false : true
            "
            >重新登录</el-button
          >
        </div>

        <!-- <div class="flex-wrap-st justify-btw">
          <a
            href="../views/register.html"
            class="login_button_control_item login"
            >没有账号？去注册</a
          >
        </div> -->
      </div>
      <p class="company_info">
        Copyright@2020 Sxreading.com 北京玛峰教育技有限公司版权所有
      </p>
    </div>
  </div>
</template>

<script>
import { captcha } from "@/api/captcha";
import { verifyPhone, passwordReset } from "@/api/resetpassword";
export default {
  data() {
    return {
      active: 0,
      sendNum: 0,
      showCount: true,
      errorStatus: false,
      focusnewpasswordStatus: false,
      focusconfirmpasswordStatus: false,
      count: "",
      warningTxt: "",
      timer: null,
      eyeStatus: "close",
      message: "Hello Vue!",
      focusUserNameStatus: false,
      focusUserPasswordStatus: false,
      focusNickNameStatus: false,
      focusCatpchaStatus: false,
      passwordType: "password",

      ruleForm: {
        mobile: "",
        captcha: "",
        nickname: "",
        password: "",
        newpassword: "",
        confirmpassword: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    verifyPhoneFc(t1, t2) {
      verifyPhone(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.active++;
          } else {
            this.$refs["captcha"].focus();
            this.$message.error(data.message);
          }
        }
      });
    },
    passwordResetFc(t1, t2) {
      passwordReset(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            // this.active++
            this.active = 3;

          }
        }
      });
    },
    registerFc() {
      if (this.active == 3) {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.active == 1) {
          if (
            this.ruleForm.newpassword == "" ||
            this.ruleForm.confirmpassword == ""
          ) {
            this.warningTxt = "请完善密码信息";
            this.errorStatus = true;
            return false;
          } else if (
            this.ruleForm.newpassword !== this.ruleForm.confirmpassword
          ) {
            this.warningTxt = "两次输入的密码不一致，请检查";
            this.errorStatus = true;
            return false;
          } else {
            this.errorStatus = false;
            this.warningTxt = "";
            if (this.active == 1) {
                 this.passwordResetFc(this.ruleForm.mobile, this.ruleForm.newpassword);
              
            } else {
              this.active++;
            }
          }
        } else if (this.active == 0) {
          this.verifyPhoneFc(this.ruleForm.mobile, this.ruleForm.captcha);
        } else {
          this.active++;
        }
      }
    },
    captchaInputFocus() {
      this.focusCatpchaStatus = true;
    },
    captchaInputBlur() {
      this.focusCatpchaStatus = false;
    },
    showPassword() {
      if (this.eyeStatus == "open") {
        this.eyeStatus = "close";
        this.passwordType = "password";
      } else {
        this.eyeStatus = "open";
        this.passwordType = "text";
      }
    },
    sendCaptcha() {
      if (this.ruleForm.mobile !== "") {
        captcha(this.ruleForm.mobile, "", 2).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
              this.countFc();
            } else {
              this.$message.error(data.message);
            }
          }
        });
      } else {
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
        this.$refs["mobile"].focus();
      }
    },
    countFc() {
      this.sendNum = this.sendNum + 1;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCount = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCount = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    clearInput(e) {
      console.log(e);
      if (e == "mobile") {
        this.ruleForm.mobile = "";
      }
      if (e == "captcha") {
        this.ruleForm.captcha = "";
      }
      if (e == "nickname") {
        this.ruleForm.nickname = "";
      }
      if (e == "password") {
        this.ruleForm.password = "";
      }
      if (e == "newpassword") {
        this.ruleForm.newpassword = "";
      }
      if (e == "confirmpassword") {
        this.ruleForm.confirmpassword = "";
      }
      this.$refs[e].focus();
    },
    userNameInputFocus() {
      this.focusUserNameStatus = true;
    },
    userNameInputBlur() {
      this.focusUserNameStatus = false;
    },
    passwordInputFocus() {
      this.focusUserPasswordStatus = true;
    },
    passwordInputBlur() {
      this.focusUserPasswordStatus = false;
    },
    newpasswordInputFocus() {
      this.focusnewpasswordStatus = true;
    },
    newpasswordInputBlur() {
      this.focusnewpasswordStatus = false;
    },
    confirmpasswordInputFocus() {
      this.focusconfirmpasswordStatus = true;
    },
    confirmpasswordInputBlur() {
      this.focusconfirmpasswordStatus = false;
    },
  },
};
</script>

<style scoped>
input {
  outline: none;
}
.success_status_icon {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 20px;
}
.success_status_txt {
  color: #999999;
  font-size: 14px;
  text-align: center;
}
.success_status_icon img {
  display: block;
  width: 100%;
}
html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
}
.body_container {
  width: 1200px;
  margin: auto;
}
.body_login_wrapper {
 
  width: 100%;
  height: 100vh;

  background: url(../assets/logo_bg.png) center no-repeat;
  background-size: cover;
}
.body_login_form {
  width: 520px;
  height: auto;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(35, 51, 83, 0.08);
  padding: 40px 50px 61px 50px;
  position: relative;
}
.company_info {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin-top: 25px;
}
.body_login_title {
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 50px;
}
.body_login_username {
  margin-bottom: 20px;
}
.login_button_wrap {
  margin-top: 30px;
  margin-bottom: 23px;
}
.login_button_wrap button {
  width: 100%;
}
.register_button {
  width: 100%;
}
.submit_button {
  width: 100%;
}
.login_input_item {
  width: 100%;
  height: 47px;
  background: #f8f9fc;
  border-radius: 2px;
  margin-bottom: 21px;
  border: 1px solid #f8f9fc;
  position: relative;
}
.login_button {
  border: none;
  width: 100%;
  height: 47px;
  background: rgba(253, 208, 0, 1);
  font-size: 14px;
  color: #333333;
  border-radius: 2px;
  cursor: pointer;
}
.showBorder {
  border: 1px solid #4393F9;
}

.login_input_item input {
  width: 80%;
  border: none;
  background: none;
}
.login_input_item_inset {
  width: 100%;
}
.login_input_item_inset_icon {
  width: 20px;
  margin-left: 20px;
  margin-right: 19px;
}
.login_input_item_inset_icon img {
  display: block;
  width: 100%;
}
.login_input_item_clear {
  width: 18px;
  cursor: pointer;
  transition: 0.5s;
  margin-right: 21px;

  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 0px;
}

.login_input_item_clear img {
  display: block;
  width: 100%;
}
.error_message {
  color: #fc605b;
  font-size: 14px;
  position: absolute;
}
.error_message img {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
}
.login_button_control_item {
  font-size: 14px;
  color: #26242a;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}
.sendCaptcha {
  color: #4393F9;
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
  padding-right: 10px;
}
.sendCaptcha label {
  cursor: pointer;
}
.login {
  color: #4393f9;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.eyes_icon {
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}
.eyes_icon img {
  width: 100%;
  display: block;
}
.progress_bar {
  margin-bottom: 37px;
}
.success_ststus_title {
  color: #26242a;
  font-size: 24px;
  text-align: center;
}
.login_logo{
  width: 180px;
  height: 36px;
  position: absolute;
  top: 35px;
  left: 18.55vw;
}
.login_logo img{
  width: 100%;
  display: inline-block;
}
</style>