

import request from '../utils/request'

export function userRegister (t1, t2, t3, t4) {
   const data = {
       username: t1,
       password: t2,
       mobile: t3,
       captcha: t4
   }
    return request({
      url: `/website/user/register`,
      method: 'post',
      data
    })
  }
  