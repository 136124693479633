<template>
  <div>
    <Header :currentIndex="currentIndex"></Header>
    <div class="jp_content_wrapper">
      <div class="jp_banner_wrapper">
        <div class="index_banner"></div>
        <div class="jp_content">
          <div class="flex-wrap-st justify-btw jp_banner_content">
            <div>
              <div class="jp_banner_txt">
                <img src="@/assets/jpbannertxt.png" alt="" />
              </div>
              <div class="jp_banner_subtitle">
                <p>
                  ⾯向中⼩学⽣、⼤学⽣，提供在线视频直播与录播⽇语课程和线下课程。
                </p>
                <p>课程包括⾼考⽇语、N考⽇语、商务⽇语及劳务⽇语培训。</p>
              </div>
            </div>
            <div class="jp_banner_content_right">
              <div class="jp_banner_content_right_title">
                <h3>行动起来</h3>
                <h3>开启新的学习体验</h3>
              </div>

              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="学生姓名" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入学生姓名"
                  ></el-input>
                </el-form-item>

                <el-form-item label="手机号" prop="mobile">
                  <el-input
                    v-model="ruleForm.mobile"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="在读学校" prop="school">
                  <el-input
                    v-model="ruleForm.school"
                    placeholder="请输入在读学校"
                  ></el-input>
                </el-form-item>

                <div class="flex-wrap-st justify-btw">
                  <el-form-item
                    label="所属区域"
                    prop="privinceId"
                    placeholder="请选择所属区域"
                  >
                    <el-select
                      v-model="ruleForm.privinceId"
                      @change="handleProvince($event, 1)"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    style="margin-left: 5px"
                    label-width="0px"
                    prop="cityId"
                    placeholder="请选择所属区域"
                  >
                    <el-select
                      v-model="ruleForm.cityId"
                      @change="handleProvince($event, 2)"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in cityList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    style="margin-left: 5px"
                    label-width="0px"
                    prop="disId"
                    placeholder="请选择所属区域"
                  >
                    <el-select v-model="ruleForm.disId" placeholder="请选择">
                      <el-option
                        v-for="item in disList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>

                <el-form-item>
                  <el-button
                    style="
                      width: 100%;
                      height: 45px;
                      margin-top: 28px;
                      background: #fdd000;
                      color: #333333;
                      font-size: 16px;
                      border: none;
                    "
                    type="primary"
                    @click="submitForm('ruleForm')"
                    >立即报名</el-button
                  >
                  <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="why_chooice_module">
        <div class="why_chooice_inset">
          <h6 class="why_chooice_inset_title">为什么选择日语不选择英语</h6>
          <!-- <p class="why_chooice_inset_subtitle">Product</p> -->

          <div class="why_chooice_inset_content">
            <div
              class="flex-wrap-st why_chooice_inset_item justify-btw"
              style="margin-bottom: 157px"
            >
              <div class="why_chooice_inset_item_thumb">
                <img src="@/assets/VIDEO.png" alt="" />
              </div>

              <div class="why_chooice_inset_item_info">
                <!-- <p class="why_chooice_inset_item_en_title">Manage your team</p> -->
                <h3 class="why_chooice_inset_item_title">
                  中国人<br/>自然就会25%的日语
                </h3>
                <p class="why_chooice_inset_item_desc">
                  日语中读音与中文汉字的发音相似
                </p>
                <p class="why_chooice_inset_item_desc">
                  日语大量使用汉字，部分日文词汇与现代汉语写法、含义相同；
                </p>
                <p class="why_chooice_inset_item_desc">
                  日语大量使用汉字，部分日文词汇与现代汉语写法、含义相同
                </p>
              </div>
            </div>

            <div class="flex-wrap-st why_chooice_inset_item justify-btw">
              <div
                class="why_chooice_inset_item_info"
                style="margin-right: 157px"
              >
                <!-- <p class="why_chooice_inset_item_en_title">Manage your team</p> -->
                <h3 class="why_chooice_inset_item_title">
                  从2019年开始用日语高考的<br/>学生人数成倍数式增长
                </h3>
                <p class="why_chooice_inset_item_desc">
                  日语中读音与中文汉字的发音相似
                </p>
                <p class="why_chooice_inset_item_desc">
                  日语大量使用汉字，部分日文词汇与现代汉语写法、含义相同；
                </p>
                <p class="why_chooice_inset_item_desc">
                  日语大量使用汉字，部分日文词汇与现代汉语写法、含义相同
                </p>
              </div>
              <div class="why_chooice_inset_item_thumb" style="margin: 0">
                <img src="@/assets/VIDEO2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="support_module">
        <div class="support_module_inset">
          <h3 class="support_module_title">高考日语适合人群</h3>

          <div class="support_module_item_wrap flex-wrap-st justify-btw">
            <div class="support_module_item">
              <div class="support_module_item_icon flex-wrap-st align-ctc">
                <img src="@/assets/support_icon001.png" alt="" />
              </div>
              <p class="support_module_item_txt">高一、高二在读学生，初中生</p>
            </div>
            <div class="support_module_item">
              <div class="support_module_item_icon flex-wrap-st align-ctc">
                <img src="@/assets/support_icon002.png" alt="" />
              </div>
              <p class="support_module_item_txt">
                英语提分困难，想考满分冲刺名牌大学
              </p>
            </div>
            <div class="support_module_item">
              <div class="support_module_item_icon flex-wrap-st align-ctc">
                <img src="@/assets/support_icon003.png" alt="" />
              </div>
              <p class="support_module_item_txt" style="padding: 0">
                喜欢动漫，日语学习能力强，以后准备赴日留学的同学
              </p>
            </div>
            <div class="support_module_item">
              <div class="support_module_item_icon flex-wrap-st align-ctc">
                <img src="@/assets/support_icon004.png" alt="" />
              </div>
              <p class="support_module_item_txt">
                音乐、体育、美术等艺考专业的学生
              </p>
            </div>
          </div>
          <!-- <p class='support_module_subtitle'>MORE THAN A SOFTWARE</p> -->
        </div>
      </div>
      <div class="why_mafengedu_module">
        <div class="why_mafengedu_inset">
          <h3 class="why_mafengedu_title">为什么选择在玛峰教育学习高考日语</h3>
          <!-- <p class="why_mafengedu_subtitle">MORE THAN SOFTWARE</p> -->

          <div class="flex-wrap-st justify-btw why_mafengedu_content">
            <div class="why_mafengedu_bg">
              <img src="@/assets/mfbg.png" alt="" />
            </div>
            <div class="why_mafengedu_txt_wrap">
              <h6 class="why_mafengedu_txt_en_title">Ma Feng Education</h6>
              <h1 class="why_mafengedu_txt_title">玛峰教育</h1>

              <ul>
                <li>
                  <label><img src="@/assets/li_icon.png" alt="" /></label>
                  师资——清华北大北师大专业教研团队+日本留学归国师资;
                </li>
                <li>
                  <label><img src="@/assets/li_icon.png" alt="" /></label>
                  时长、每周课时——45分钟/节课，与英语课一致;
                </li>
                <li>
                  <label><img src="@/assets/li_icon.png" alt="" /></label>
                  <p>
                    教材、教案——《新版中日交流标准日本语（第二版）》/《初/中级日语听力教程》
                  </p>
                  <p>
                    新赛道《高考日语精读1》/《高考日语精读2》/《高考日语蓝宝书语法》/《高考日语
                  </p>

                  <p>
                    橙宝书阅读》/《高考日语绿宝书听力》/《高考日语黄报书写作》/《10年高考日语真题》/《高考日语模拟题》
                  </p>
                </li>

                <li style="margin-top: 40px">
                  <label><img src="@/assets/li_icon.png" alt="" /></label>
                  <p>学习模式：</p>
                  <div>
                    <p>学习阶段测验——单元检测周考、月考、期中/期末考试</p>
                    <p>
                      课下作业跟踪——老师要求做到当天作业当天批改；单词打卡作业每日APP打卡完成
                    </p>
                    <p>
                      班级人数——50人：走班制(只有日语课单独出来上课，其余课程在原班级进行）
                    </p>
                    <p>
                      配套课下学习资源配置——单词App,
                      视频课，音频资源，高考习题App
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="star_teacher_module">
        <div class="star_teacher_inset">
          <div class="star_teacher_start_title">明星教师</div>
          <p class="star_teacher_start_subtitle">
            文案描述，大概说一下师资力量等专业团队核心竞争力，文案描述，
          </p>
          <p class="star_teacher_start_subtitle">
            大概说一下师资力量等专业团队核心竞争力，文案描述，
          </p>

          <el-carousel
            indicator-position="outside"
            :autoplay='false'
            height="600px"
            class="start_teacher_content"
          >
            <el-carousel-item class="flex-wrap-st">
              <div class="star_teacher_item">
                <div class="star_teacher_item_inset">
                <img src="@/assets/wangxiaofei.png" alt="" />
                <p class="teacher_w">教学总监.高考教研组组长</p>
                <p class="teacher_n">王笑飞</p>

                <div class="teacher_intro">
                  ⽇本新潟⼤学硕⼠
                  10年⽇语教学经验，烁享⽇语王牌教师，负责基础⽇语教学以及⽇语考试教学，独特的教学⻛格深受学员好评。
                  学员⽇语N1、N2⽔平通过率98.5% ，学⽣⾼考成绩100分以上者93.6%
                </div>
                </div>
              </div>

              <div class="star_teacher_item">
                <div class="star_teacher_item_inset">
                <img src="@/assets/cuilisa.png" alt="" />
                <p class="teacher_w">高考日语一线教师</p>
                <p class="teacher_n">崔莉飒</p>

                <div class="teacher_intro">
                  毕业于河北师范大学日语专业，日语N1水平，曾留学于日本岛根大学。5年教学经验，熟悉高考日语重难点，教学风格轻松活泼，简洁突出。
                  力争与学生建立平等和谐的师生关系，善于教导学生短时高效学习日语。
                </div>
                </div>
              </div>

              <div class="star_teacher_item">
                <div class="star_teacher_item_inset">
                <img src="@/assets/luyanan.png" alt="" />
                <p class="teacher_w">高考日语一线教师</p>
                <p class="teacher_n">卢雅楠</p>

                <div class="teacher_intro">
                 毕业于内蒙古大学，日语专业硕士，日语N1水平，持有高级中学日语教师资格证，曾留学于日本。有6年教学经验，熟悉高考日语重难点，授课风格活泼有趣，课堂把控应变能力强。
热爱教育行业，责任心强，力求激发学生日语学习的兴趣，引导学生轻松高效学习日语。
                </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item class="flex-wrap-st">
              <div class="star_teacher_item">
                <div class="star_teacher_item_inset">
                <img src="@/assets/dongaijuan.png" alt="" />
                <p class="teacher_w">高考日语一线教师</p>
                <p class="teacher_n">董爱娟</p>

                <div class="teacher_intro">
                  毕业于山西财经大学日语专业，日语N1水平。
                  5年教学经验，日语基础扎实，能准确把握日语高考重难点，熟悉日语高考命题特点和命题倾向。
                  性格开朗活泼，上课生动有趣，力争为学生创造轻松愉快的学习氛围。
                </div>
                </div>
              </div>

              <div class="star_teacher_item">
                <div class="star_teacher_item_inset">
                <img src="@/assets/wangweijie.png" alt="" />
                <p class="teacher_w">高考日语一线教师</p>
                <p class="teacher_n">王玮洁</p>

                <div class="teacher_intro">
                  高考日语一线教师
毕业于河北经贸大学日语专业，日语N1、专业CATTI翻译，曾就职于日本softbank公司。
6年资深教学经验，熟悉高考日语考点设置，对重点难点深有研究。教学风格多样，简洁突出重点，课堂轻松活泼。
能与学生建立和谐幽默的师生关系，善于引导式教学，有效提高学生学习效率。
                </div>
                </div>
              </div>

              <div >
               
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="ac_module">
        <div class="ac_module_inset">
          <h1>行动起来开启新的学习体验</h1>
          <!-- <p>Trusted by 2M members across 120+ countries</p> -->
          <div @click="goTop">立即报名</div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/footer";
import Header from "../components/header";
import { websiteProvince, provonceChildren } from "../api/address";
import { getToken, removeToken } from "@/utils/auth"; // get token from cookie
import "swiper/swiper.scss";
import { signUp } from "../api/signUp";
export default {
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      value: [],
      options: [],
      cityList: [],
      disList: [],
      loginStatus: true,

      currentIndex: 1,
      registerForm: {},
      registerFormRules: {},
      ruleForm: {
        name: "",
        region: "",
        privinceId: "",
        mobile: "",
        school: "",
        cityId: "",
        disId: "",
      },
      rules: {
        name: [{ required: true, message: "请输入学生姓名", trigger: "blur" }],

        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
        ],
        school: [
          { required: true, message: "请输入在读学校", trigger: "change" },
        ],
        privinceId: [
          { required: true, message: "请选择所在省", trigger: "change" },
        ],
        cityId: [
          { required: true, message: "请选择所在市", trigger: "change" },
        ],
        disId: [{ required: true, message: "请选择所在区", trigger: "change" }],
      },
    };
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    signUpFc(t1, t2, t3, t4, t5, t6) {
      signUp(t1, t2, t3, t4, t5, t6).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.$message.success(data.message);

            this.$refs["ruleForm"].resetFields();
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    handleProvince(x, y) {
      this.provonceChildrenFc(x, y);
    },
    goTop() {
      window.scrollTo(0, 0);
    },
    handleChange() {},
    provonceChildrenFc(t1, t2) {
      provonceChildren(t1, t2).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            if (t2 == 1) {
              this.cityList = [];
              this.disList = [];
              this.ruleForm.cityId = "";
              this.ruleForm.disId = "";
            } else {
              this.disList = [];
              this.ruleForm.disId = "";
            }
            data.data.forEach((item) => {
              let obj = {
                value: item.id,
                label: item.name,
              };
              if (t2 == 1) {
                this.cityList.push(obj);
              } else {
                this.disList.push(obj);
              }
            });
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    websiteProvinceFc() {
      websiteProvince().then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            data.data.forEach((item) => {
              let obj = {
                value: item.id,
                label: item.name,
              };
              this.options.push(obj);
            });

            // this.$router.replace('/index')
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.signUpFc(
            this.ruleForm.name,
            this.ruleForm.school,
            this.ruleForm.mobile,
            this.ruleForm.privinceId,
            this.ruleForm.cityId,
            this.ruleForm.disId
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  created() {
    this.websiteProvinceFc();
    if (getToken()) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
    console.log(this.loginStatus);
  },
};
</script>

<style scoped>
.jp_content {
  width: 1200px;
  margin: auto;
  margin-top: 100px;
}
.jp_banner_wrapper {
  background: url(../assets/jpbanner.png) center no-repeat;
  background-size: cover;
  height: 800px;
  width: 100%;
  overflow: hidden;
  margin-top: -80px;
}
.jp_banner_txt {
  width: 382px;
  margin-top: 171px;
  margin-bottom: 15px;
}
.jp_banner_txt img {
  width: 100%;
  display: block;
}
.jp_banner_content {
  margin-top: 0;
}
.jp_banner_subtitle p {
  font-size: 16px;
  margin: 0;
  padding: 0;
  color: #d2d2d2;
  line-height: 34px;
}
.jp_banner_content_rebtn {
  width: 255px;
  height: 45px;
  border-radius: 4px;
  background: #fdd000;
  font-size: 16px;
  color: #333333;
  text-align: center;
  line-height: 45px;
  margin-top: 71px;
}
.jp_banner_content_right {
  width: 600px;
  background: rgba(6, 39, 51, 0.5);
  padding: 31px 50px 94px 50px;
  flex-shrink: 0;
  position: relative;
  margin-top: 50px;
}
.jp_banner_content_right_title h3 {
  font-size: 24px;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  font-weight: normal;
  margin: 0;
  margin-bottom: 24px;
}
.jp_banner_content_right_input {
  margin-top: 24px;
  position: relative;
}

.jp_banner_content_right_input_item {
  background: rgba(248, 249, 252, 0.3);
  border-radius: 2px;
  margin-bottom: 20px;
  height: 45px;
  position: relative;
}
.jp_banner_content_right_input_item img {
  width: 11px;
  display: inline-block;
  margin-left: 15px;
  margin-top: 4px;
  margin-right: 10px;
}

.re_sub_button {
  width: 100%;
  height: 45px;
  background: #fdd000;
  border-radius: 2px;
  font-size: 16px;
  color: #333333;
  text-align: center;
  line-height: 45px;
  margin-top: 58px;
  cursor: pointer;
}
.re_error_message {
  color: #fc605b;
  font-size: 14px;
  position: absolute;
  bottom: 65px;
  left: 0;
  width: 100%;
}
.re_error_message label {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
}
.re_error_message label img {
  display: block;
  width: 100%;
}
.send_code {
  font-size: 14px;
  color: #fdd000;
  right: 11px;
  position: absolute;
  cursor: pointer;
}
.why_chooice_module {
  background: #ffffff;
  padding: 266px 0 198px 0;
}
.why_chooice_inset {
  width: 1200px;
  margin: auto;
  height: auto;
}
.why_chooice_inset_title {
  font-size: 34px;
  line-height: 60px;
  color: #0b162b;
  text-align: center;
  font-weight: normal;
  margin-bottom: 5px;
}
.why_chooice_inset_subtitle {
  font-size: 14px;
  color: #8c8c8c;
  text-align: center;
  margin: 0;
  font-weight: normal;
}
.why_chooice_inset_content {
  margin: 116px auto 0 auto;
  width: 1113px;
}
.why_chooice_inset_item_thumb {
  margin-right: 77px;
}
.why_chooice_inset_item_thumb img {
  display: block;
  width: 100%;
}
.why_chooice_inset_item_info {
}
.why_chooice_inset_item_en_title {
  font-size: 16px;
  color: #7e8085;
  margin: 0;
  margin-bottom: 11px;
  margin-top: 29px;
}
.why_chooice_inset_item_title {
  font-size: 34px;
  color: #2c2e30;
  font-weight: normal;
  margin-bottom: 22px;
}
.why_chooice_inset_item_desc {
  margin: 0;
  color: #54565a;
  line-height: 160%;
}
.support_module {
  width: 100%;
  height: 508px;
  background: url(../assets/support_bg.png) center no-repeat;
  background-size: cover;
}
.support_module_inset {
  width: 1200px;
  height: auto;
  margin: auto;
  padding: 70px 0 143px 0;
}
.support_module_title {
  font-size: 34px;
  color: #ffffff;
  text-align: center;
  font-weight: normal;
  margin: 0;
  line-height: 60px;
  margin-bottom: 75px;
}
.support_module_subtitle {
  font-size: 16px;
  color: #7e8085;
  text-align: center;
  margin-top: 17px;
}
.support_module_item {
  width: 240px;
}
.support_module_item_icon {
  width: 60px;
  margin: auto;
  margin-bottom: 50px;
  /* min-height: 45px; */
}
.support_module_item_icon img {
  width: 100%;
  display: block;
}
.support_module_item_txt {
  color: #ffffff;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  padding: 0 25px;
}
.why_mafengedu_module {
  width: 100%;
  padding: 173px 0 198px 0;
  background: #ffffff;
}
.why_mafengedu_inset {
  width: 1200px;
  margin: auto;
}
.why_mafengedu_title {
  font-size: 34px;
  color: #2c2e30;
  line-height: 46px;
  text-align: center;
  font-weight: normal;
}
.why_mafengedu_subtitle {
  font-size: 16px;
  color: #7e8085;
  text-align: center;
  margin-top: 17px;
}
.why_mafengedu_bg {
  width: 539px;
  flex-shrink: 0;
  margin-right: 54px;
}
.why_mafengedu_bg img {
  width: 100%;
  display: block;
}
.why_mafengedu_content {
  margin-top: 113px;
}
.why_mafengedu_txt_en_title {
  font-size: 14px;
  color: #050d31;
  font-weight: normal;
  margin-bottom: 9px;
  margin-top: 37px;
}
.why_mafengedu_txt_title {
  color: #333333;
  line-height: 54px;
  font-size: 34px;
  font-weight: normal;
  margin-bottom: 32px;
}
.why_mafengedu_txt_wrap ul {
  margin: 0;
  padding: 0;
}
.why_mafengedu_txt_wrap ul li {
  list-style: none;
  color: #333333;
  font-size: 14px;
  line-height: 180%;
  margin-bottom: 5px;
  position: relative;
}
.why_mafengedu_txt_wrap ul li p {
  margin: 0;
}
.why_mafengedu_txt_wrap ul li label {
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -20px;
  top: -3px;
}
.why_mafengedu_txt_wrap ul li label img {
  display: inline-block;
  width: 100%;
}
.why_mafengedu_txt_wrap a {
  text-decoration: none;
  font-size: 12px;
  color: #1d3ede;
  margin-top: 33px;
  display: inline-block;
}

.star_teacher_module {
  width: 100%;
  height: auto;
  padding: 91px 0 173px 0;
  background: #f9f9f9;
}
.star_teacher_inset {
  width: 1255px;
  height: auto;

  margin: auto;
}
.star_teacher_start_title {
  font-size: 34px;
  color: #2c2e30;
  text-align: center;
  font-weight: normal;
  line-height: 46px;
  margin-bottom: 8px;
}
.star_teacher_start_subtitle {
  font-size: 16px;
  color: #7e8085;
  margin: 0;
  padding: 0;
  text-align: center;
}
.star_teacher_item {
  width: 33.33333%;
  min-height: 570px;
  border-radius: 4px;
  overflow: hidden;
}
.star_teacher_item_inset{
  width: 95.6%;
  margin: auto;
  background: #ffffff;
  padding: 30px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

}
.teacher_w {
  font-size: 16px;
  color: #7e8085;
  margin: 0;
  margin-top: 28px;
  margin-bottom: 12px;
}
.start_teacher_content {
  margin-top: 78px;
}
.teacher_n {
  font-size: 36px;
  color: #0b162b;
  font-weight: normal;
}
.teacher_intro {
  font-size: 16px;
  color: #636363;
  line-height: 150%;
  text-align: justify;
}
.ac_module {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 145px 0 152px 0;
}
.ac_module_inset {
  width: 1200px;
  margin: auto;
}
.ac_module_inset h1 {
  font-size: 48px;
  color: #0b162b;
  line-height: 60px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: normal;
}
.ac_module_inset p {
  font-size: 18px;
  color: #9ba0a9;
  text-align: center;
  margin: 0;
}
.ac_module_inset div {
  width: 450px;
  height: 60px;
  background: #fdd000;
  color: #333333;
  font-size: 18px;
  text-align: center;
  line-height: 60px;
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 4px;
}
.star_teacher_item_inset img{
  width: 100%;
  display: block;
}
>>> .el-form-item__label {
  color: #ffffff;
}
</style>