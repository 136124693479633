<template>
    <div class="body_content">
         <Header :currentIndex="currentIndex"></Header>
         <div class="index_wrap">
            <div class="index_banner_wrapper">
                <div class="index_banner">
                    <div class="index_banner_logo">
                        <img src="@/assets/banner_logo.png" alt="">
                    </div>
                </div>
           
                <div class="index_banner_intro">
                    <p>玛峰教育是⼀家创新型互联⽹教育企业，致⼒于建设⺟语学习与外语⼩语种学习平台与内容研发现代化智慧校园软件平台。</p>
                    <p>⼤学、中⼩学的⼩语种课程<label>内容提供者</label>、</p>
                    <p>中⼩学⽣⺟语学习的<label>平台建设者</label>、</p>
                    <p>各类学校及培训机构的智慧校园管理软件<label>创新研发者</label>。</p>
                </div>
            </div>

            <div class="index_s_module">
                <div class="index_s_module_inset">
                    <h4>玛峰日语</h4>

                    <div class="flex-wrap-st justify-btw">
                        <div class="about_c_item">
                            <div class="about_c_item_img">
                                <img src="@/assets/test_thumb.png" alt="">
                            </div>
                            <div class="about_c_item_info">
                                <label>派驻学校&线，上课程</label>
                                <h3>高考日语</h3>
                                <p>⾯向⾼中学⽣开设的⽇语课程</p>
                                <p>适合英语基础薄弱或冲刺⾼分学</p>
                                <p>⽣提升⾼考外语成绩</p>
                                <!-- <img src="@/assets/test_thumb.png" alt=""> -->
                            </div>
                        </div>
                        <div class="about_c_item">
                            <div class="about_c_item_img">
                                <img src="@/assets/bussnis_thumb.png" alt="">
                            </div>
                            <div class="about_c_item_info">

                                <label>集训班&线上课程</label>
                                <h3>商务日语</h3>
                                <p>⾯向出国劳务⼈员、赴⽇⼯作⼈</p>
                                <p>员开设的⽇语课程 </p>
                                <p>掌握⽇语商务交流能⼒</p>
                                <!-- <img src="@/assets/test_thumb.png" alt=""> -->
                            </div>
                        </div>
                        <div class="about_c_item">
                            <div class="about_c_item_img">
                                <img src="@/assets/pass_thumb.png" alt="">
                            </div>
                            <div class="about_c_item_info">

                                <label>线上课程&保过</label>
                                <h3>日语考级</h3>
                                <p>N1\N2\N3\N4\N5</p>
                                <p>零基础直通课程 </p>
                                <p>及任意基础N考等级提升课程</p>
                                <!-- <img src="@/assets/test_thumb.png" alt=""> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="index_iss_module">


            <div class="index_iss_module_bg_wrap">
                <!-- <div class="index_iss_module_bg">
                    <img src="@/assets/issSystembg.png" alt="">
                </div> -->
                <div class="iss_system_inset flex-wrap-st">
                    <div class="iss_system_inset_left">
                        <div>
                            <h3 class="iss_system_inset_left_title">智慧校园</h3>
                            <p class="iss_system_inset_left_en_title">Intelligent school system</p>
                        </div>

                        <p class="iss_system_inset_left_subtitle">
                            为学校提供⼀站式线上管理平台帮助学校进⾏精细化管理升级，从招⽣录取到学⽣毕业，全⾯数据化管理学⽣在校学习与⽣活。
                        </p>

                        <div class="iss_system_inset_left_thumb">
                            <img src="@/assets/issSystemthumb.png" alt="">
                        </div>
                    </div>

                    <div class="iss_system_inset_right">
                        <div class="flex-wrap-st iss_system_inset_right_item">
                            <div class="iss_system_inset_right_item_thumb">
                                <img src="@/assets/iss_icon001.png" alt="">
                            </div>
                            <div class="iss_system_inset_right_item_info">
                                <div class="iss_system_inset_right_item_info_title">
                                    <h3>校园管理系统</h3>
                                    <p>一站式管理平台方方面面</p>
                                </div>
                                <div class="iss_system_inset_right_item_subtitle">
                                    招⽣管理 教职⼯管理 学⽣管理 考试管理 考勤管理 奖惩管理 收费管理 排课管理 教室管理
                                </div>
                            </div>
                        </div>

                        <div class="flex-wrap-st iss_system_inset_right_item">
                            <div class="iss_system_inset_right_item_thumb">
                                <img src="@/assets/iss_icon002.png" alt="">
                            </div>
                            <div class="iss_system_inset_right_item_info">
                                <div class="iss_system_inset_right_item_info_title">
                                    <h3>学生成长</h3>
                                    <p>基于考勤、作业、考试等数据  记录学⽣成⻓轨迹</p>
                                </div>
                                <div class="iss_system_inset_right_item_subtitle">
                                    考勤记录 作业记录 奖惩记录<br/> 成绩曲线 任职记录 特⻓管理
                                </div>
                            </div>
                        </div>
                        <div class="flex-wrap-st iss_system_inset_right_item">
                            <div class="iss_system_inset_right_item_thumb">
                                <img src="@/assets/iss_icon003.png" alt="">
                            </div>
                            <div class="iss_system_inset_right_item_info">
                                <div class="iss_system_inset_right_item_info_title">
                                    <h3>家校互动</h3>
                                    <p>充分向家⻓提供学⽣在校信息并有效降低沟通时间成本</p>
                                </div>
                                <div class="iss_system_inset_right_item_subtitle">
                                  查看学⽣考勤、作业、考试情况<br/> 收取学校通知 打卡 提交作业
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
<!-- 


        <div class="index_recommend_module">
            <div class="index_recommend_module_inset">
                <h4 class="index_recommend_module_inset_title">精选报告</h4>

                <div class="index_recommend_item_wrap">
                    <div class="index_recommend_item" v-for="(item) in articleData" :key='item.id' @click="getItemInfo(item.id, item.price)">
                   
                        <div class="item_tag">{{item.price}}元阅读</div>

                        <div class="index_recommend_item_wrap_content">
                            <div class="flex-wrap-st justify-btw">
                                <div class="index_recommend_item_icon">
                                    
                                    <img :src="item.cover" alt="">
                                </div>
                                <div class="index_recommend_item_content">
                                    <h4>{{item.title}}</h4>
                                    <p>{{item.subtitle}}</p>
                                </div>
                            </div>

                            <div class="index_recommend_item_wrap_content_footer flex-wrap-st align-ctc justify-ctc">
                                即刻了解
                            </div>
                        </div>
                    </div>

                    <div style="clear:both"></div>
                 
                    <div class="clr"></div>
                </div>
            </div>
        </div> -->

        <div class="index_link_module">
            <div class="index_link_inset">
                  <h5 class="index_link_inset_title">合作机构</h5>
                <ul class="flex-wrap-st justify-sad align-ctc">
                    <li v-for="(item) in linkListData" :key='item.id'>
                        <a target="_blank" :href="item.link">
                            <img :src="item.image" alt="">
                        </a>
                    </li>
                 
                </ul>

              
                <!-- <p class="index_link_inset_sub_title">放上已经合作的学校logo和线上合作的平台logo</p> -->
                <div class="join_us_button" @click="goConactUs">加入我们</div>
            </div>
        </div>

        <Footer></Footer>

        

        <div class="payPop">
            <el-dialog title="微信支付" center :visible.sync="payDialogVisible" width='311px' :show-close='false'>
            <!-- <div slot='title'  center>微信支付</div> -->
            <div class="payImg_er">
                <label>¥{{price}}</label>
                <img :src="payImg" alt="">
            </div>
                <p class="company_name">北京玛峰教育科技有限公司</p>


                <div class="content_footer">
                    <div class="content_footer_title flex-wrap-st justify-ctc align-ctc">
                        <div><img src="@/assets/wechartpayicon.png" alt=""></div>
                        <label>微信支付</label>
                    </div>
                    <div class="payStatusBtn" @click="checkStatus">我已完成支付</div>
                </div>
            </el-dialog>
        </div>


       <el-dialog
        title="提示"
        :visible.sync="noLoginStatus"
        width="350px"
        :before-close="handleNoLoginClose">
        <span>登录后才能查看文章哦</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="noLoginStatus = false">取 消</el-button>
            <el-button type="primary" @click="goLogin">去登录</el-button>
        </span>
        </el-dialog>


    </div>
</template>

<script>
// import Footer from "../../components/footer.vue";
import Footer from '../components/footer';
import Header from '../components/header';
import { articleList, linksList, artiteminfo } from '../api/index'
import { articleDetail } from '../api/newsDetail'
export default {
    components: {
        Footer,
        Header
     
    },
  data () {
      return {
          currentIndex: 0,
          noLoginStatus: false,
          payDialogVisible: false,
          articleData: [],
          linkListData: [],
          payImg: '',
          price: '',
          currentItemId: ''
      }
  },
  methods: {
      goConactUs() {
          this.$router.push(
             {
                path: '/contactUs',
                query: {
                    type:"index"
                }
             }
          )
      },
      goLogin() {
          this.$router.push('/login')
      },
      checkStatus() {
          this.articleDetailFc(this.currentItemId)
      },
      handleNoLoginClose() {
          this.noLoginStatus = false
      },
      goNewsDetail(e) {
          
          this.$router.push({
              path: '/newsDetail',
              query: {
                  id: e
              }
          })
      },
      artiteminfoFc(t1) {
          artiteminfo(t1).then(res => {
              
              
              
            if (res.data.code == 200) {
                let data = res.data.data
                
                if (data.error == 0) {
                    // console.log(data)
                
                    this.payDialogVisible = true
                    this.payImg = data.data
                } else {
                 
                 

                   this.goNewsDetail(t1)
                }
            } else {
                this.noLoginStatus = true
            }
          })
      },
      getItemInfo(e, x){
          this.artiteminfoFc(e)
          this.price = x
          this.currentItemId = e 
      },
      articleDetailFc(t1) {
          articleDetail(t1).then(res => {
            if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
                this.goNewsDetail(this.currentItemId)
            
            } else {
            //   this.$message.error(data.message);
            this.$message.error('支付失败，请检查')
            }
          }
          })
      },
      articleListFc(){
        articleList().then(res => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
             this.articleData = data.data.data
            } else {
              this.$message.error(data.message);
            }
          }
        })

      },
      linksListFc(){
        linksList().then(res => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
           
             this.linkListData = data.data
            } else {
              this.$message.error(data.message);
            }
          }
        })

      }
  },
  created () {
      this.articleListFc()
      this.linksListFc()
  }
}
</script>

<style scoped>
.payPop >>>.el-dialog__header{
    background: #21AB39;
    padding: 27px 0;
}
.payPop >>>.el-dialog__body{
    background: #21AB39;
    padding: 0;
}
.payPop >>>.el-dialog__title{
    color: #ffffff;
}
.content_footer{
    background: #ffffff;
    padding: 18px 0px;
}
.index_wrap{
    width: 100%;
    margin: auto;
    position: relative;
}

.body_content{
    min-width: 1200px
}
.index_banner{
   width: 100%;
    height: 800px;
    background: url(../assets/banner.png) center no-repeat;
    background-size: cover;
    margin-top: -80px;
    position:relative;
}
.index_banner_logo{
    width: 273px;
    position: absolute;
    bottom: 114px;
    left: 50%;
    margin-left: -136px;

}
.index_banner_logo img{
    width: 100%;
    display: block;
}
.index_content{
    width: 1200px;
    height: 800px;
    margin: auto;
}

.index_banner_wrapper{
    background: #ffffff;
}
.index_banner_intro{
    text-align: center;
    margin-top: 86px;
    margin-bottom: 86px;
}
.index_banner_intro label{
    color: #FDD000;
}
.index_banner_intro p{
    font-size: 16px;
    color: #333333;
    line-height: 34px;
    padding: 0;
    margin: 0;
}

.index_s_module{
    background: #F9F9F9;
    padding: 155px 0px 199px 0;
}
.index_s_module_inset{
    width: 1200px;
    margin: auto;
}
.index_s_module_inset h4{
    font-size: 34px;
    color: #0B162B;
    font-weight: normal;
    text-align: center;
    line-height: 60px;
    margin-bottom: 124px;
}
.about_c_item_info label{
    font-size: 13px;
    color: #FFFFFF;
    background: #FDD000;
    border-radius: 3px;
    display: inline-block;
    padding: 3px 14px;
    margin-bottom: 16px;
}
.about_c_item_info{
    padding: 30px 31px 38px 31px;
}
.about_c_item{
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
}
.about_c_item h3{
    font-size: 28px;
    color: #111111;
    margin-bottom: 11px;
    font-weight: normal;
}
.about_c_item p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #111111;
}
.index_iss_module{
   
    background: url(../assets/issSystembg.png) no-repeat center;
  
    background-size: cover;

}

.iss_system_inset{
    width: 1200px;
    margin: auto;
    height: auto;
}
.iss_system_inset_left{
    width: 483px;
    height: auto;
    background: #ffffff;
    flex-shrink: 0;
    padding: 27px 46px 91px 46px;
}
.iss_system_inset_left_title{
    font-size: 34px;
    color: #333333;
    line-height: 60px;
    margin-bottom: 3px;
    font-weight: normal;
}
.iss_system_inset_left_en_title{
    font-size: 16px;
    color: #333333;
    line-height: 24px;
}
.iss_system_inset_left_subtitle {
   font-size: 16px;
   color: #333333;
   line-height: 28px;
   margin-bottom: 33px;
}
.iss_system_inset_left_thumb{
    width: 261px;
}
.iss_system_inset_left_thumb img{
    width: 100%;
    display: block;
}
.iss_system_inset_right{
    margin-left: 119px;
    flex-shrink: 0;
    width: 598px;
}
.iss_system_inset_right_item{
    margin-bottom: 71px;
}
.iss_system_inset_right_item:last-child{
    margin: 0;
}
.iss_system_inset_right_item_thumb{
    width: 125px;
    margin-right: 46px;
    flex-shrink: 0;
}
.iss_system_inset_right_item_thumb img{
    width: 100%;
    display: block;
}
.iss_system_inset_right_item_info_title h3{
  font-size: 24px;
  color: #FFFFFF;
  line-height: 34px;
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.iss_system_inset_right_item_info_title p{
    font-size: 14px;
    color: #FFFFFF;
    margin: 0;
     padding: 0;
}
.iss_system_inset_right_item_subtitle{
    color: #ffffff;
    font-size: 16px;
    line-height: 28px;
    margin-top: 15px;
    
}
.index_iss_module_bg_wrap{
    
    margin: auto;

    padding: 197px 0px 233px 0;
    /* background: url(../image/issSystembg.png) no-repeat center; */
    position: relative;
    /* background-size: cover; */
}
.index_iss_module_bg{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.index_iss_module_bg img{
    width: 100%;
    display: block;
    /* filter: blur(7px); */
}
.index_recommend_module {
    padding: 213px 0 223px 0;
    background: #F9F9F9;
    overflow: hidden;
}
.index_recommend_module_inset{
    width: 1200px;
    margin: auto;
    height: auto;
    

}
.index_recommend_module_inset .index_recommend_module_inset_title{
    font-size: 34px;
    color: #0B162B;
    font-weight: normal;
    text-align: center;
}
.index_recommend_item_wrap{
    margin-top: 124px;
}
.index_recommend_item{
    width: 380px;
    height: 190px;
    background: #ffffff;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    box-shadow: 0 2px 5px 5px rgba(0, 0, 0, .08);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
    
}
.index_recommend_item:hover .index_recommend_item_wrap_content_footer{
    background: #FDD000;
    transition: .1s;
}
.index_recommend_item_bg{
    position: absolute;
    width: 145px;
    height: 145px;
    right: 0;
    top: 0;
}
.index_recommend_item_wrap_content{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 25px 17px  25px 33px;
}
.index_recommend_item_wrap_content_footer{
    width: 100%;
    height: 45px;
    background: #F8F5E5;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #333333;
}
.index_recommend_item_bg img{
    width: 100%;
    display: block;
}
.index_recommend_item .item_tag{
    width: 100px;
    height: 16px;
    background: #FDD000;
    position: absolute;
    right: -28px;
    top: 13px;
    transform: rotate(45deg);
    font-size: 12px;
    text-align: center;
}
.index_recommend_item:nth-child(3n){
    margin-right: 0;
}
.index_recommend_item_icon{
    flex-shrink: 0;
    width: 60px;
    margin-right: 20px;
  
}
.index_recommend_item_icon img{
    display: block;
    width: 100%;
}
.index_recommend_item_content{
    width: 250px;
}
.index_recommend_item_content h4{
    font-size: 22px;
    color: #333333;
    margin-bottom: 5px;
}
.index_recommend_item_content p{
    font-size: 14px;
    color: #333333;
}
.index_link_module{
    width: 100%;
    height: auto;
    background: #ffffff;
    padding: 100px 0 
}
.index_link_inset{
    width: 1200px;
    height: auto;
    margin: auto;
}
.index_link_inset  li{
    list-style: none;
    margin: 0;
    padding: 0;
}
.index_link_inset .index_link_inset_title{
    font-size: 34px;
    color: #0B162B;
    line-height: 60px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 45px;
}
.index_link_inset .index_link_inset_sub_title{
  font-size: 18px;
  color: #0B162B;
  margin-top: 16px;
  margin-bottom: 29px;
  text-align: center;
}
.index_link_inset .join_us_button{
    width: 450px;
    height: 50px;
    background: #FDD000;
    font-size: 18px;
    color: #333333;
    text-align: center;
    line-height: 50px;
    margin: 80px auto 0 auto;
    cursor: pointer;
}
.payImg_er{
    width: 200px;
    height: auto;
    display: block;
    margin: auto;
    background: #ffffff;
    color: #ffffff;
}
.payImg_er label{
    color: #333333;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px 0;
    
}
.payImg_er img{
    width: 100%;
    display: block;
}
.company_name{
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 19px;
}
.content_footer_title{
    margin-bottom: 19px;
}
.content_footer_title img{
    width: 48px;
    margin-right: 14px;

}
.content_footer_title label{
    font-size: 32px;
    color: #333333;
    font-weight: normal;
}
.payStatusBtn{
    width: 255px;
    height: 45px;
    border-radius: 4px;
    background: #21AB39;
    color: #ffffff;
    text-align: center;
    line-height: 45px;
    margin: auto;
    cursor: pointer;
}
</style>