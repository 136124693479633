<template>
  <div class="wrap">
     <Header :currentIndex="currentIndex" :navStyle='navStyle'></Header>
  
    <div class="newsDetail_content flex-wrap-st justify-btw">
      <div class="newsDetail_content_left">
        <!-- <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="#">精选报告</a></li>
                      <li class="breadcrumb-item active" aria-current="page">日语找那个的“音读”和“训读”</li>
                    </ol>
                  </nav> -->

        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">精选报告</el-breadcrumb-item>
          <!-- <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item> -->
          <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item> -->
          <el-breadcrumb-item>{{newsDetail.title}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="newsDetail_content_left_txt">
          <h2>{{newsDetail.title}}</h2>
          <p class="newsDetail_content_left_publish_time">
            发表于{{newsDetail.published}}
          </p>
          <div class="newsDetail_desc">
            {{newsDetail.subtitle}}
          </div>

          <div class="newsDetail_content_info" v-html="newsDetail.body">
            {{newsDetail.body}}
          </div>
        </div>
      </div>
      <div class="newsDetail_content_right">
        <div class="newsDetail_content_right_title">你可能会喜欢</div>

        <div class="recommend_article_wrap">
          <div class="recommend_article_item" v-for='(item) in articleData.data' :key="item.id" @click="goNewsDetail(item.id, item.price)">
            <div class="recommend_article_thumb">
              <img :src="item.cover" alt="" />
              <div class="recommend_article_thumb_tag">{{item.price}}元阅读</div>
            </div>
            <div class="recommend_article_info">
              <h6>{{item.title}}</h6>
              <p class="flex-wrap-st align-ctc">
                <label><img src="@/assets/time.png" alt="" />
                </label>发表于{{item.published.substr(0, 10)}}
              </p>
            </div>
          </div>
        </div>

        <div class="changeOther" @click="redo">换一批</div>
      </div>
    </div>

    <Footer></Footer>

    <div class="payPop">
            <el-dialog title="微信支付" center :visible.sync="payDialogVisible" width='311px' :show-close='false'>
            <!-- <div slot='title'  center>微信支付</div> -->
            <div class="payImg_er">
                <label>¥{{price}}</label>
                <img :src="payImg" alt="">
            </div>
                <p class="company_name">北京玛峰教育科技有限公司</p>


                <div class="content_footer">
                    <div class="content_footer_title flex-wrap-st justify-ctc align-ctc">
                        <div><img src="@/assets/wechartpayicon.png" alt=""></div>
                        <label>微信支付</label>
                    </div>
                    <div class="payStatusBtn" @click="checkStatus">我已完成支付</div>
                </div>
            </el-dialog>
        </div>
  </div>
</template>

<script>
import { articleDetail } from "../api/newsDetail";
import Footer from "../components/footer";

import Header from "../components/header";
import { articleList, artiteminfo} from '../api/index'

export default {
  components: {
    Footer,
    Header,
  },
  data() {
      return {
        newsDetail: {},
        payDialogVisible: false,
        payImg: '',
          price: '',
        articleData: [],
        currentIndex: 5,
        navStyle:'blacks',
        price: '',
        listQuery: {
          page:1,
          limit: 3
        },
        currentId: ''
      }
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {
      goNewsDetail(e, x) {
        this.currentId = e
        articleDetail(e).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data
            if (data.error == 0) {
              this.$router.push({
              path: '/newsDetail',
                  query: {
                      id: e
                  }
              })
            } else {
               this.artiteminfoFc(e, x)
              
            }
          }
        })
          
      },
      artiteminfoFc(t1, t2) {
          artiteminfo(t1).then(res => {
              
              
              
            if (res.data.code == 200) {
                let data = res.data.data
                
                if (data.error == 0) {
                    // console.log(data)
                
                    this.payDialogVisible = true
                    this.payImg = data.data
                    this.price = t2
                } else {
                 
                 

                   this.goNewsDetail(t1)
                }
            } else {
                this.noLoginStatus = true
            }
          })
      },
      checkStatus() {
          articleDetail(this.currentId).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
               this.$router.push({
              path: '/newsDetail',
                  query: {
                      id: this.currentId
                  }
              })
            } else {
              this.$message.error(data.message);
            }
          }
        });
      },
      articleListFc(t1, t2){
        articleList(t1, t2).then(res => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
              this.articleData = data.data
            } else {
              this.$message.error(data.message);
            }
          }
        })

      },
      redo(){
 


         let query = this.listQuery

                    if (query.page !== this.articleData.last_page) {
                        query.page = query.page+1
                        this.listQuery = query

                         this.articleListFc(this.listQuery.page, this.listQuery.limit)
                    } else {
                        query.page = 1
                        this.listQuery = query

                         this.articleListFc(this.listQuery.page, this.listQuery.limit)
                    }


        
      },
    articleDetailFc(t1) {
      articleDetail(t1).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.newsDetail = data.data
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
  },



  mounted () {

     this.articleDetailFc(this.$route.query.id)
     this.articleListFc(this.listQuery.page, this.listQuery.limit)
  }
};
</script>

<style scoped>
.newsDeatil_header {
  width: 100%;
  height: 80px;
  /* box-shadow: 0 3px 6px rgba(125, 125, 125, 0.2); */
}
.newsDeatil_header_inset {
  width: 1200px;
  margin: auto;
}
.newsDeatil_header .index_content_header_nav a {
  color: #333333;
}
.newsDeatil_header .index_content_header_login_wrap label {
  background: #dfdfdf;
}
.newsDetail_content {
  width: 1200px;

  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
}
.newsDetail_content_left {
  width: 750px;
}
.newsDetail_content_right {
  width: 345px;
  margin-top: 60px;
}
.newsDetail_content_left_txt {
  margin-top: 29px;
}
.newsDetail_content_left_txt h2 {
  font-size: 42px;
  color: #212121;
  font-weight: normal;
  margin-bottom: 20px;
}
.newsDetail_content_left_publish_time {
  font-size: 12px;
  color: #a0a0a0;
  margin-bottom: 24px;
}
.newsDetail_desc {
  padding: 12px 19px;
  background: #f9f9f9;
  font-size: 14px;
  color: #666666;
  line-height: 180%;
  margin-bottom: 30px;
}
.newsDetail_content_info p {
  margin-bottom: 39px;
  font-size: #212121;
  line-height: 150%;
  text-align: justify;
}
.newsDetail_content_info img {
  width: 100%;
  display: block;
}
.newsDetail_content_right_title {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 30px;
  padding-bottom: 8px;
  font-size: 16px;
  color: #212121;
}
.recommend_article_thumb {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    min-height: 172px;

}
.recommend_article_thumb_tag {
  position: absolute;
  width: 65px;
  height: 20px;
  background: #fdd000;
  bottom: 10px;
  left: 10px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
}
.recommend_article_thumb img {
  width: 100%;
  display: block;
}
.recommend_article_info h6 {
  font-size: 16px;
  color: #212121;
}
.recommend_article_info p {
  font-size: 11px;
  color: #a0a0a0;
}
.recommend_article_info p label {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 3px;
}
.recommend_article_info p label img {
  width: 100%;
  display: block;
}
.recommend_article_item {
  margin-bottom: 54px;
  cursor: pointer;
}
.recommend_article_item:hover{
  color: 93;
}
.recommend_article_item:last-child{
  margin-bottom: 20;
}
.changeOther:hover{
  color: #4393F9;
}
.payPop >>>.el-dialog__header{
    background: #21AB39;
    padding: 27px 0;
}
.payPop >>>.el-dialog__body{
    background: #21AB39;
    padding: 0;
}
.payPop >>>.el-dialog__title{
    color: #ffffff;
}
.payImg_er{
    width: 200px;
    height: auto;
    display: block;
    margin: auto;
    background: #ffffff;
    color: #ffffff;
}
.payImg_er label{
    color: #333333;
    font-size: 14px;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px 0;
    
}
.payImg_er img{
    width: 100%;
    display: block;
}
.company_name{
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 19px;
}
.content_footer_title{
    margin-bottom: 19px;
}
.content_footer_title img{
    width: 48px;
    margin-right: 14px;

}
.content_footer_title label{
    font-size: 32px;
    color: #333333;
    font-weight: normal;
}
.payStatusBtn{
    width: 255px;
    height: 45px;
    border-radius: 4px;
    background: #21AB39;
    color: #ffffff;
    text-align: center;
    line-height: 45px;
    margin: auto;
    cursor: pointer;
}
.content_footer{
    background: #ffffff;
    padding: 18px 0px;
}
.changeOther{
  text-align: center;
    font-size: 14px;
    padding: 10px 0;
    color: #999999;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin: auto;
}
</style>