
import request from '../utils/request'

export function captcha (t1, t2, t3) {
    const data = {
        mobile: t1,
        register_verify: t2,
        type: t3
        
    }
    return request({
      url: `/website/user/captcha`,
      method: 'post',
      data
    })
  }
  