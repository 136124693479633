<template>
  <div>
    <div class="body_login_wrapper flex-wrap-st justify-ctc align-ctc">
      <router-link to='/index' class="login_logo">
        <img src="@/assets/login_logo.png" alt="">
    </router-link>
      <div>
        <div class="body_login_form" v-if="active == 0">
          <h1 class="body_login_title">注册</h1>
          <!-- <el-input placeholder="请输入手机号码"  v-model='ruleForm.mobile'>
                    <template slot="prepend"><i class="el-icon-mobile"></i></template>
                  </el-input> -->
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusUserNameStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_phone.png" alt="" />
              </div>
              <input
                type="text"
                @focus="userNameInputFocus"
                ref="mobile"
                @blur="userNameInputBlur"
                v-model="ruleForm.mobile"
                maxlength="11"
                placeholder="请输入手机号码"
              />
              <div
                v-if="ruleForm.mobile !== ''"
                @click="clearInput('mobile')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusCatpchaStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_captcha.png" alt="" />
              </div>
              <input
                type="number"
                @focus="captchaInputFocus"
                ref="captcha"
                @blur="captchaInputBlur"
                v-model="ruleForm.captcha"
                maxlength="6"
                placeholder="请输入验证码"
              />
              <div
                style="right: 70px"
                v-if="ruleForm.captcha !== ''"
                @click="clearInput('captcha')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>

              <div
                class="sendCaptcha"
                v-show="showCount"
                @click="sendCaptcha"
                v-cloak
              >
                <label v-show="sendNum == 0">发送验证码</label>
                <label v-show="sendNum == 1">重新发送</label>
              </div>
              <div
                class="sendCaptcha"
                v-show="!showCount"
                v-cloak
                style="color: #9b9b9b; font-size: 14px"
              >
                {{ count }}秒后重新试
              </div>
            </div>
          </div>
          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusNickNameStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_username.png" alt="" />
              </div>
              <input
                type="text"
                @focus="nickNameInputFocus"
                ref="nickname"
                @blur="nickNameInputBlur"
                v-model="ruleForm.nickname"
                maxlength="11"
                placeholder="请输入昵称"
              />
              <div
                v-if="ruleForm.nickname !== ''"
                @click="clearInput('nickname')"
                class="login_input_item_clear"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>
            </div>
          </div>

          <div
            class="login_input_item flex-wrap-st align-ctc"
            :class="focusUserPasswordStatus ? 'showBorder' : ''"
          >
            <div class="login_input_item_inset flex-wrap-st align-ctc">
              <div class="login_input_item_inset_icon">
                <img src="@/assets/register_password.png" alt="" />
              </div>
              <input
                :type="passwordType"
                @focus="passwordInputFocus"
                ref="password"
                minlength="6"
                maxlength="20"
                v-model="ruleForm.password"
                @blur="passwordInputBlur"
                placeholder="密码为6-20位，至少含数字/字母/字符两种组合"
              />
              <div
                v-if="ruleForm.password !== ''"
                @click="clearInput('password')"
                class="login_input_item_clear"
                style="right: 20px"
              >
                <img src="@/assets/close_btn.png" alt="" />
              </div>

              <div class="eyes_icon" @click="showPassword">
                <img
                  src="@/assets/openeye.png"
                  alt=""
                  v-show="eyeStatus == 'open'"
                />
                <img
                  src="@/assets/eyeclose.png"
                  alt=""
                  v-show="eyeStatus == 'close'"
                />
              </div>
            </div>
          </div>
          <div
            class="error_message flex-wrap-st align-ctc"
            v-show="errorStatus"
          >
            <img src="@/assets/error_message_icon.png" alt="" />
            {{ warningTxt }}
          </div>
          <p class="register_allow">点击注册即为同意 <label @click="showRules">《玛峰用户协议》</label></p>
          <div class="login_button_wrap">
            <!-- <button disabled>注册</button> -->
            <el-button
              @click="registerFc"
              class="register_button"
              type="warning"
              :disabled="
                ruleForm.mobile !== '' &&
                ruleForm.captcha !== '' &&
                ruleForm.nickename !== '' &&
                ruleForm.password !== ''
                  ? false
                  : true
              "
              >注册</el-button
            >
          </div>

          <div class="flex-wrap-st justify-btw">
              <router-link class="login_button_control_item login" to='/login'>使用已有账户登录</router-link>
            
          </div>
        </div>
        <div class="body_login_form" v-else>
            <div class="register_status">
                <div class="register_status_icon">
                  <img src="@/assets/successStatus.png" alt="">
                </div>
                <div class="register_status_title">
                    注册成功
                </div>
                <p class="register_status_subtitle">5秒后自动放回登录页面，</p>
                <div class="login_button_wrap">
                  <button class="login_button" @click="goLogin">去登录</button>
          
          </div>
            </div>
            
        </div>
        <p  class="company_info">
          Copyright@2020 Sxreading.com 北京玛峰教育技有限公司版权所有
        </p>
      </div>
    </div>

    <el-dialog
      title="玛峰用户协议"
      :visible.sync="showRulesDialog"
      :close-on-click-modal='true'
      width="40%"
      :before-close="handleClose">
      <div style="max-height: 600px; overflow-y:scroll">
        《玛峰教育用户使用协议》
                <h6>【特别提示】</h6>
                1. 请您（以下亦称“用户”）仔细阅读本协议全部条款，并确认您已完全了解本协议之规定。
                2. 未成年人应当在监护人陪同和指导下阅读本协议，并在使用本协议项下服务前取得监护人的同意，若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
                3. 请您审慎阅读、充分理解各条款内容，并选择接受或不接受本协议，特别是免除或者限制责任的条款、法律适用和争议解决条款，免除或者限制责任的条款将以粗体标识，您应重点阅读。您的注册、登录、使用等行为将视为对本协议的接受，并视为您同意接受本协议各项条款的约束，本协议将构成您与“玛峰教育”之间直接有约束力的法律文件。
                4. 请确认您依照中华人民共和国法律（为本协议之目的，含行政法规、部门规章、规范性文件，下同）及您所在地之法律、及您自愿或应当接受其管辖之法律（以下合称“法律”），有权签署并同意接受本协议的约束。如果您代表您的雇主或单位，请在签署前，确认并保证您已获得签署本协议的充分授权。
                <h6> 一、定义</h6>
                1.1 本协议所称“玛峰教育”是北京玛峰教育科技有限公司及其关联方与其主办的玛峰教育小程序、微信公众号、H5等传播平台之合称。
                1.2 本协议所称关联方是指控制某一方的、或被某一方所控制的、或与某一方共同受控制于同一实体的任何企业。控制是指直接或间接拥有该企业百分之五十（50％）以上的股权、投票权或管理权。
                <h6>二、服务内容</h6>
                2.1 “玛峰教育”是一家网络服务提供者，可针对不同传播渠道用户为其提供综合的知识内容服务，前述传播渠道包括但不限于：
                2.1.1 通过“玛峰教育”的服务；
                2.1.2 “玛峰教育”提供的可以使用的其他传播渠道。
                2.2 除免费服务外，“玛峰教育”可能提供部分收费服务。您可以通过付费方式购买收费服务，具体方式为：通过“玛峰教育”提供的第三方支付平台向您玛峰教育账户中预充相应的货币，然后通过您玛峰教育账户支付一定数额的货币，从而获得收费服务使用权限。 对于收费服务，“玛峰教育”会在您使用之前给予明确的提示，只有根据提示确认同意并完成支付，才能使用该等收费服务。支付行为的完成以银行或第三方支付平台生成“支付已完成”的确认通知为准。
                2.3 您理解，“玛峰教育”仅提供与“玛峰教育”服务相关的技术服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网络而支付的手机费）均由您自行负担。
                <h6>三、服务的变更、中断及终止</h6>
                3.1 您理解网络服务具有特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他“玛峰教育”无法控制的情形），若发生中断或终止部分或全部服务的情况， “玛峰教育”将尽可能及时通过网页公告、系统通知、短信或其他合理方式进行通知。
                3.2 您理解，“玛峰教育”会定期或不定期地对提供服务的“玛峰教育”平台（包括但不限于应用程序）进行检修或者维护，如因此类情况而造成服务中断， “玛峰教育”会尽可能事先进行通告。
                3.3 “玛峰教育”服务的更新。
                3.3.1 为了改善用户体验、完善服务内容、 保证服务的安全性、功能的一致性，“玛峰教育”将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、功能改变、版本升级等形式）。
                3.3.2 本软件新版本发布后，旧版本的软件可能无法使用。“玛峰教育”不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
                3.4 您有权向“玛峰教育”申请注销您的账号，具体注销条件及程序请参照本协议中的附件《玛峰教育账号注销须知》。
                <h6>四、商业活动</h6>
                您了解并接受，“玛峰教育”可能通过玛峰教育小程序、手机短信、网页或其他合法方式向用户发送商品促销或其他相关商业信息，包括但不限于通知信息、宣传信息、广告信息等。
                五、账号的注册
                5.1 您需要在“玛峰教育”注册一个账号，并按照“玛峰教育”的要求为该账号设置密码，才可以使用该账号登录并使用“玛峰教育”。您可依照“玛峰教育”的要求修改您的账号密码、昵称、登录所使用的手机号等信息。
                5.2 您注册账号时，应按照“玛峰教育”的提示及要求填写或提供资料、信息，并确保用户身份及信息的真实性、正确性和完整性；如果资料发生变化，您应及时更改。您同意并承诺：
                5.2.1不故意冒用他人信息为您注册账号；
                5.2.2未经他人合法授权不以他人名义注册账号；
                5.2.3不使用色情、暴力或侮辱、诽谤他人等违反公序良俗的词语注册账号；
                5.2.4不进行其他不当的账号注册的行为。
                如您违反前述规定，“玛峰教育”有权随时拒绝、限制您使用该账号，或者注销该账号。
                5.3 您理解，“玛峰教育”账号的所有权归“玛峰教育”运营方所有，您完成申请注册流程后，获得相应账号的使用权。账号使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。非初始申请注册人不得通过承租、受让或者其他任何方式使用“玛峰教育”账号。
                <h6>六、账号的使用</h6>
                6.1 您有责任妥善保管账号信息及密码，您同意在任何情况下不向他人透露账号及密码信息。当在您怀疑账号被他人使用时，应立即通知“玛峰教育”。您应自行使用账号，并对任何利用您的账号及密码所进行的活动负完全的责任。
                6.2 您应了解，在账号和密码匹配时，“玛峰教育”无法对非法或未经您授权使用您账号及密码的行为作出甄别，因此，“玛峰教育”对任何使用您账号和密码登录并使用“玛峰教育”的行为不承担任何责任。您同意并承诺：
                6.2.1 当您的账号或密码遭到未获授权的使用，或者发生其他任何安全问题时，您会立即有效通知到“玛峰教育”，同时您理解“玛峰教育”对您的任何请求采取行动均需要合理时间，且“玛峰教育”应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大；
                6.2.2当您长时间不使用“玛峰教育”服务时，会将有关账号安全登出，以保证您的账号安全。
                6.3账号因您主动泄露、遭他人攻击、诈骗等行为导致的损失及后果，您应通过司法、行政等救济途径向侵权行为人追偿。
                6.4 “玛峰教育”有权根据自己的判定，在怀疑账号被不当使用时，拒绝或限制账号使用，或注销该账号，同时终止本协议。
                6.5您同意，遵守法律及本协议规定，秉承善意使用“玛峰教育”，且保证：
                6.5.1 不会利用“玛峰教育”进行非法活动、或进行侵犯他人权利或损害他人利益的活动；
                6.5.2 不会以非法方式获取或利用“玛峰教育”其他用户的信息；
                6.5.3 不会以技术方式攻击、破坏或改变“玛峰教育”的部分或全部、或干扰其运行；
                6.5.4 不会以非法方式获取或使用“玛峰教育”的任何软件、代码或其他技术或商业信息；
                6.5.5 不会对“玛峰教育”运行的任何程序进行反向工程、反向编译、反向汇编或改写；
                6.5.6 不会未经许可使用“玛峰教育”的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由北京玛峰教育科技有限公司（及关联方）或“玛峰教育”享有知识产权或权利的信息或资料；
                6.5.7 不会侵犯北京玛峰教育科技有限公司（及关联方）或“玛峰教育”的商标权、著作权、专利权、其他知识产权或其他合法权利或权益，不会以任何方式贬损“玛峰教育”的商业声誉；
                6.5.8 不以商业目的使用“玛峰教育”，不对“玛峰教育”的任何部分或全部进行商业性质利用、复制、拷贝、出售、调查、广告，不将“玛峰教育”用于其他任何商业目的或商业性质的活动，但“玛峰教育”与您另行签订有协议或“玛峰教育”另行指定可供您使用的特定商业服务除外。
                6.5.9 不会利用“玛峰教育”进行其他违反法律法规或违背公序良俗的活动。
                如您违反上述约定，“玛峰教育”可能将您违反法律或侵犯第三方权利或权益的记录报告给行政主管部门、司法机关。
                6.6 您在“玛峰教育”上传或发表的内容，“玛峰教育”有权进行审核。您应保证您为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出异议，“玛峰教育”有权根据实际情况进行处理，包括但不限于删除相关的内容等，且有权追究您的法律责任。
                6.7 您应当确保您在“玛峰教育”所发布的信息不包含以下内容：
                6.7.1 违反国家法律法规禁止性规定的；
                6.7.2 政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
                6.7.3 欺诈、虚假、不准确或存在误导性的；
                6.7.4 侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
                6.7.5 侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
                6.7.6 存在可能破坏、篡改、删除、影响“玛峰教育”任何系统正常运行或未经授权秘密获取“玛峰教育”及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
                6.7.7 其他违背社会公共利益或公共道德或依据相关“玛峰教育”规则的规定不适合在“玛峰教育”上发布的。
                <h6>七、隐私保护</h6>
                7.1 “玛峰教育”可能需要您填写能识别用户身份的个人信息以便可以在必要时联系您，或为提供更好的使用体验。“个人信息”包括但不限于用户的姓名、性别、年龄、联系电话、地址、受教育情况、您使用手机号或第三方账号授权注册所包含的大致的位置信息等。
                7.2 “玛峰教育”承诺，将按照法律法规规定的方式及保证您安全稳定使用“玛峰教育”的方式获取、使用、保存、管理您在注册或使用“玛峰教育”过程中提供的个人信息，不以非法方式披露您的个人信息。
                7.3 您同意“玛峰教育”按照《玛峰教育隐私条款》对用户个人信息进行获取、使用、保存、管理和保护，具体条款详见“玛峰教育”内展示的《玛峰教育隐私条款》。 
                <h6>八、知识产权</h6>
                8.1 北京玛峰教育科技有限公司对“玛峰教育”服务及“玛峰教育”软件所包含的受知识产权法或其他法律保护的资料享有相应的权利。“玛峰教育”的整体内容（包括但不仅限于设计图样以及其他图样、产品、服务名称等）的知识产权归北京玛峰教育科技有限公司所享有，任何人不得使用、复制或用作其他用途。
                8.2 经由“玛峰教育”展示、传送的文字、图片、音频、视频及其他内容，受到著作权法、商标法、专利法或其他法律法规的保护，未经北京玛峰教育科技有限公司书面授权许可，您不得自行或许可第三方进行复制、转载、修改、出租、售卖或衍生其他作品。
                8.3 “玛峰教育”对其专有内容、原创内容和其他通过授权取得的独占或者独家内容享有知识产权。未经“玛峰教育”书面许可，任何单位和个人不得私自复制、转载、传播和提供收听服务或者有其他侵犯“玛峰教育”识产权的行为。否则，将承担法律责任。
                8.4 为了促进知识的分享和传播，您将在“玛峰教育”上发表的内容（包括但不仅限于留言、评论、笔记），授予“玛峰教育”免费且不可撤销的非独家使用许可，“玛峰教育”有权将该内容用于“玛峰教育”各种形态的产品和服务上，包括但不限于“玛峰教育”以及“玛峰教育”发表的应用或其他互联网产品。您对“玛峰教育”的前述授权并不改变您发布内容的所有权及知识产权归属，也并不影响您行使对所发布内容的合法权利。
                <h6>九、免责声明</h6>
                9.1 为方便您使用，“玛峰教育”可能会提供第三方互联网网站或资源链接。您应了解，除非另有声明，“玛峰教育”无法对第三方网站服务进行控制。您因下载、传播、使用或依赖上述网站或资源所生的损失，由您自行承担责任。
                9.2 “玛峰教育”尽可能保证其稳定运行。 您应理解并同意，因法律、政策、技术、经济、管理的原因，“玛峰教育”不会因以下情形出现而对您承担责任：
                9.2.1 “玛峰教育”无法使用、中断使用或无法完全适合用户的使用要求；
                9.2.2 “玛峰教育”受到干扰，无法及时、安全、可靠运行，或出现任何错误；
                9.2.3 经由“玛峰教育”取得的任何产品、服务（含收费服务）或其他材料不符合您的期望；
                9.2.4 用户资料遭到未经授权的使用或修改。
                9.3 “玛峰教育”可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。如您注销“玛峰教育”账号，或“玛峰教育”所提供的服务终止或取消，“玛峰教育”可以从服务器上永久地删除用户的数据，除法律有明确规定外，此种情形下，“玛峰教育”无法向用户返还或提供任何数据。
                9.4 您应从“玛峰教育”官网或其他拥有“玛峰教育”授权的第三方获得安装程序。如果你从未经“玛峰教育”授权的第三方获取“玛峰教育”安装程序或名称相同的安装程序，“玛峰教育”无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
                9.5 您在使用“玛峰教育”服务的过程中应遵守国家法律法规及政策规定，您使用“玛峰教育”服务而产生的行为后果由您自行承担。
                9.6 您通过“玛峰教育”发布的任何信息，及通过“玛峰教育”传递的任何观点不代表“玛峰教育”之立场，“玛峰教育”亦不对其完整性、真实性、准确性或可靠性负责。您对于可能会在“玛峰教育”上接触到的非法的、非道德的、错误的或存在其他失宜之处的信息，及被错误归类或是带有欺骗性的发布内容，应自行做出判断。在任何情况下，因前述非正当信息而导致的任何损失或伤害，应由相关行为主体承担全部责任。
                <h6>十、违约责任</h6>
                10.1 如因“玛峰教育”违反有关法律、法规或本协议项下的任何条款而给您造成损失的，相应的损害赔偿责任由“玛峰教育”承担。
                10.2 您同意保障和维护“玛峰教育”及其他用户的合法权益，如因您违反有关法律、法规或本协议项下的任何条款而给“玛峰教育”或任何其他任何第三方造成损失，您同意承担由此造成的损害赔偿责任。
                <h6>十一、协议变更与终止</h6>
                11.1 协议变更
                11.1.1 “玛峰教育”可根据国家法律、法规或政策变化，保护消费者权益，维护交易秩序的需要，不时修改本协议。
                11.1.2 本协议如果变更，“玛峰教育”将尽可能提前在“玛峰教育”发布通知。但您应了解并同意，“玛峰教育”有可能未经事先通知，而修改或变更本协议的全部或部分内容，您也应随时关注本协议是否有修改或变更之情形。
                11.1.3 如果本协议修改或变更，而您不能接受修改或变更后的内容，您可以停止使用“玛峰教育”。如果您继续使用“玛峰教育”，则表明您完全接受并愿意遵守修改或变更后的本协议。
                11.2 协议的中止与终止
                11.2.1 本协议或本协议项下“玛峰教育”所提供的服务（含收费服务）可在下述情形下部分或全部中止或终止：
                1）因法律规定，或“玛峰教育”服从行政命令或司法判令的要求；
                2）用户违反本协议；
                3）您盗用他人账号、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为的；
                4）在成功注销您的账号后；
                5）“玛峰教育”认为应予终止的其他情形。
                11.2.2 在一般情形下，“玛峰教育”会提前按照您提供的联系方式以电子邮件或短信或其他方式通知您，服务将中止或终止。您应了解并同意，在紧急情况或不可抗力的情况下，“玛峰教育”可不经通知即中止或终止服务。
                11.2.3 在本协议或本协议项下“玛峰教育”提供的服务（含收费服务）中止时，“玛峰教育”有权拒绝用户登录“玛峰教育”。
                11.2.4 在本协议或本协议项下“玛峰教育”提供的服务（含收费服务）终止时，“玛峰教育”有权：
                1）停止用户登录“玛峰教育”
                2）删除用户信息；
                3）删除用户购买的内容。
                11.2.5 您同意，不因本协议或本协议项下“玛峰教育”所提供的服务中止或终止，而要求“玛峰教育”向您作出赔偿或补偿或承担任何其他责任，但法律规定应由玛峰教育承担责任的除外。
                <h6>十二、通知</h6>
                您在注册成为“玛峰教育”用户并接受“玛峰教育”服务时，应向“玛峰教育”提供真实有效的联系方式（包括但不限于联系电话等），对于联系方式发生变更的，您应及时更新有关信息，并保持可被联系的状态。“玛峰教育”将按照前述联系方式向您发送各类通知，此类通知的内容可能会对您的权利义务产生影响，请您务必及时关注。
                <h6>十三、法律适用</h6>
                您在使用“玛峰教育”时，应遵守中华人民共和国法律、及您所在地之法律、及您自愿或应当接收其管辖之法律，本协议的解释及履行应按照中华人民共和国法律进行。
                <h6>十四、争议解决</h6>
                您和“玛峰教育”均同意，因本协议解释或执行引起的任何争议，双方应首先友好协商解决。协商不成时，则任一方均可将争议提交北京仲裁委员会依据其届时有效的仲裁规则以仲裁方式解决。仲裁地点为北京，仲裁语言为中文。仲裁裁决为终局的，对各方均有法律约束力。
                <h6>十五、其他</h6>
                15.1 “玛峰教育”可能不时发布针对用户的相关协议，并可能将该相关协议作为对本协议的补充或修改而将其内容作为本协议的一部分。请您及时关注并阅读相关协议。
                15.2 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
                附件1：《玛峰教育账号注销须知》
                特别提示：
                您在申请注销前，应当认真阅读《玛峰教育账号注销须知》（以下称“注销须知”）。 请您务必审慎阅读、充分理解注销须知中相关条款内容，其中包括：
                （1）与您约定免除或限制责任的条款；
                (2）其他以粗体标识的重要条款。
                如您对本《注销须知》有任何疑问，可拨打我们的客服电话联系客服，您也可联系在线客服。
                当您完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。如您在阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账号注销程序。
                1. 注销的结果
                注销玛峰教育账号，您将无法再使用本玛峰教育账号，也将无法找回您玛峰教育账号中及与账号相关的任何内容或信息，玛峰教育亦无法协助您重新恢复该等服务。该等内容或信息包括但不限于：
                （1）账号信息、会员权益、虚拟资产、已购产品；
                （2）无法使用账号原验证手机登录玛峰教育服务；
                （3）解除与第三方授权或绑定关系；
                （4）本玛峰教育账号的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息、学习数据、证书等）都将无法找回；
                （5）您曾获得的优惠券、积分、资格、订单及其他卡券等视为您自行放弃，将无法继续使用。
                2. 注销玛峰教育账号后，您的个人信息我们会在玛峰教育的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定，相关交易记录须在玛峰教育后台保存【3】年甚至更长的时间。
                3. 账号注销条件
                如果您仍执意注销账号，您的账号需同时满足以下条件：
                （1）在最近一个月内，账号没有进行更改密码、更改绑定信息等敏感操作；
                （2）自愿放弃账号在玛峰教育系统中的资产和虚拟权益自愿放弃；
                （3）账号内无未完成的订单和服务；
                （4）账号已经解除与其他网站、其他APP的授权登录或绑定关系；
                （5）账号不存在任何未决争议，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等。
                4. 在账号注销期间，如果您的玛峰教育账号涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，玛峰教育有权自行终止本玛峰教育账号的注销而无需另行玛峰教育您的同意。
                5. 注销本玛峰教育账号并不代表本玛峰教育账号注销前的账号行为和相关责任玛峰教育豁免或减轻。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showRulesDialog = false">取 消</el-button>
        <el-button type="primary" @click="showRulesDialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userRegister } from "../api/register";
import { captcha } from "../api/captcha";
export default {
  data() {
    return {
      active: 0,
      sendNum: 0,
      showCount: true,
      errorStatus: false,
      showRulesDialog: false,
      count: "",
      warningTxt: "",
      timer: null,
      eyeStatus: "close",
      message: "Hello Vue!",
      focusUserNameStatus: false,
      focusUserPasswordStatus: false,
      focusNickNameStatus: false,
      focusCatpchaStatus: false,
      passwordType: "password",
      ruleForm: {
        mobile: "",
        captcha: "",
        nickname: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    showRules(){
      this.showRulesDialog = true
    },
    handleClose() {
      this.showRulesDialog = false
    },
      goLogin() {
          this.$router.replace('/login')
      },
    userRegisterFc(t1, t2, t3, t4) {
      userRegister(t1, t2, t3, t4).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            
            this.active = 1
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
    registerFc() {
      let r = this.ruleForm;
      if (
        r.mobile.length !== 11 ||
        r.captcha == "" ||
        r.nickname == "" ||
        r.password.length < 6 ||
        r.password.length > 16
      ) {
        this.errorStatus = true;
        this.warningTxt = "请根据提示输入正确的信息";
      } else {
        this.errorStatus = false;

        this.userRegisterFc(
          this.ruleForm.nickname,
          this.ruleForm.password,
          this.ruleForm.mobile,
          this.ruleForm.captcha
        );
      }
    },
    showPassword() {
      if (this.eyeStatus == "open") {
        this.eyeStatus = "close";
        this.passwordType = "password";
      } else {
        this.eyeStatus = "open";
        this.passwordType = "text";
      }
    },
    sendCaptcha() {
      if (this.ruleForm.mobile !== "") {
        captcha(this.ruleForm.mobile, 1, 1).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data.error == 0) {
              this.countFc();
            } else {
              this.$message.error(data.message);
            }
          }
        });
      } else {
        this.$message({
          message: "请输入手机号码",
          type: "warning",
        });
        this.$refs["mobile"].focus();
      }
    },
    countFc() {
      this.sendNum = this.sendNum + 1;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCount = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCount = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    clearInput(e) {
      if (e == "mobile") {
        this.ruleForm.mobile = "";
      }
      if (e == "captcha") {
        this.ruleForm.captcha = "";
      }
      if (e == "nickname") {
        this.ruleForm.nickname = "";
      }
      if (e == "password") {
        this.ruleForm.password = "";
      }
      this.$refs[e].focus();
    },
    userNameInputFocus() {
      this.focusUserNameStatus = true;
    },
    userNameInputBlur() {
      this.focusUserNameStatus = false;
    },
    passwordInputFocus() {
      this.focusUserPasswordStatus = true;
    },
    passwordInputBlur() {
      this.focusUserPasswordStatus = false;
    },
    captchaInputFocus() {
      this.focusCatpchaStatus = true;
    },
    captchaInputBlur() {
      this.focusCatpchaStatus = false;
    },
    nickNameInputFocus() {
      this.focusNickNameStatus = true;
    },
    nickNameInputBlur() {
      this.focusNickNameStatus = false;
    },
    clearUsername() {
      this.ruleForm.name = "";
    },
    blurText() {
      console.log("33333");
    },
    clearPassword() {
      this.ruleForm.password = "";
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
input {
  outline: none;
}
html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
}
.body_container {
  width: 1200px;
  margin: auto;
}

.body_login_wrapper {
 
  width: 100%;
  height: 100vh;

  background: url(../assets/logo_bg.png) center no-repeat;
  background-size: cover;
}
.body_login_form {
  width: 520px;
  height: auto;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(35, 51, 83, 0.08);
  padding: 40px 50px 61px 50px;
  position: relative;
}
.company_info {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin-top: 25px;
}
.body_login_title {
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 50px;
}
.body_login_username {
  margin-bottom: 20px;
}
.login_button_wrap {
  margin-top: 30px;
  margin-bottom: 23px;
}
.login_button_wrap button {
  width: 100%;
}
.register_button {
  width: 100%;
}
.submit_button {
  width: 100%;
}
.login_input_item {
  width: 100%;
  height: 47px;
  background: #f8f9fc;
  border-radius: 2px;
  margin-bottom: 21px;
  border: 1px solid #f8f9fc;
  position: relative;
}
.login_button {
  border: none;
  width: 100%;
  height: 47px;
  background: rgba(253, 208, 0, 1);
  font-size: 14px;
  color: #333333;
  border-radius: 2px;
  cursor: pointer;
}
.showBorder {
  border: 1px solid #4393F9;
}

.login_input_item input {
  width: 80%;
  border: none;
  background: none;
}
.login_input_item_inset {
  width: 100%;
}
.login_input_item_inset_icon {
  width: 20px;
  margin-left: 20px;
  margin-right: 19px;
}
.login_input_item_inset_icon img {
  display: block;
  width: 100%;
}
.login_input_item_clear {
  width: 18px;
  cursor: pointer;
  transition: 0.5s;
  margin-right: 21px;

  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 0px;
}

.login_input_item_clear img {
  display: block;
  width: 100%;
}
.error_message {
  color: #fc605b;
  font-size: 14px;
  position: absolute;
}
.error_message img {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
}
.login_button_control_item {
  font-size: 14px;
  color: #26242a;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}
.sendCaptcha {
  color: #4393F9;
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
  padding-right: 10px;
}
.sendCaptcha label {
  cursor: pointer;
}
.login {
  color: #4393F9;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.eyes_icon {
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}
.eyes_icon img {
  width: 100%;
  display: block;
}
.register_status_icon{
    width: 120px;
    height: 120px;
    margin: auto;
    margin-bottom: 32px;
}
.register_status_icon img{
    width: 100%;
    display: block;
}
.register_status_title{
    font-size: 24px;
    color: #26242A;
    text-align: center;
}
.register_status_subtitle{
    font-size: 14px;
    color: #999999;
    margin-top: 24px;
    text-align: center;
}
.login_logo{
  width: 180px;
  height: 36px;
  position: absolute;
  top: 35px;
  left: 18.55vw;
}
.login_logo img{
  width: 100%;
  display: inline-block;
}
.register_allow{
  font-size: 14px;
  color: #999999;
}
.register_allow label{
  cursor: pointer;
  font-weight: bold;
  color: #4393F9;
}
</style>