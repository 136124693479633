<template>
  <div class="body_login_wrapper flex-wrap-st justify-ctc align-ctc">
    <router-link to='/index' class="login_logo">
        <img src="@/assets/login_logo.png" alt="">
    </router-link>
    <div>
      <div class="body_login_form">
        <h1 class="body_login_title">登录</h1>

        <div
          class="login_input_item flex-wrap-st align-ctc"
          :class="focusUserNameStatus ? 'showBorder' : ''"
        >
          <div class="login_input_item_inset flex-wrap-st align-ctc">
            <div class="login_input_item_inset_icon">
              <img src="@/assets/register_phone.png" alt="" />
            </div>
            <input
              v-cloak
              type="text"
              @focus="userNameInputFocus"
              ref="mobile"
              @blur="userNameInputBlur"
              v-model="ruleForm.mobile"
              maxlength="11"
              placeholder="请输入手机号码"
            />
            <div
              v-if="ruleForm.mobile !== ''"
              @click="clearInput('mobile')"
              v-cloak
              class="login_input_item_clear"
            >
              <img src="@/assets/close_btn.png" alt="" />
            </div>
          </div>
        </div>

        <div
          v-cloak
          class="login_input_item flex-wrap-st align-ctc"
          :class="focusUserPasswordStatus ? 'showBorder' : ''"
        >
          <div class="login_input_item_inset flex-wrap-st align-ctc">
            <div class="login_input_item_inset_icon">
              <img src="@/assets/register_password.png" alt="" />
            </div>
            <input
              v-cloak
              :type="passwordType"
              @focus="passwordInputFocus"
              ref="password"
              minlength="6"
              maxlength="20"
              v-model="ruleForm.password"
              @blur="passwordInputBlur"
              placeholder="请输入密码"
            />
            <div
              v-if="ruleForm.password !== ''"
              v-cloak
              @click="clearInput('password')"
              class="login_input_item_clear"
              style="right: 20px"
            >
              <img src="@/assets/close_btn.png" alt="" />
            </div>

            <div class="eyes_icon" @click="showPassword">
              <img
                src="@/assets/openeye.png"
                alt=""
                v-show="eyeStatus == 'open'"
              />
              <img
                src="@/assets/eyeclose.png"
                alt=""
                v-show="eyeStatus == 'close'"
              />
            </div>
          </div>
        </div>
        <div
          v-cloak
          class="error_message flex-wrap-st align-ctc"
          v-show="errorStatus"
        >
          <img src="@/assets/error_message_icon.png" alt="" />
          {{ warningTxt }}
        </div>
        <div class="login_button_wrap">
          <!-- <button disabled>注册</button> -->
          <el-button
            @click="registerFc"
            class="register_button"
            type="warning"
            :disabled="
              ruleForm.mobile !== '' && ruleForm.password !== '' ? false : true
            "
            >登录</el-button
          >
        </div>

        <div class="flex-wrap-st justify-btw no_acc">
          <div @click="goRegister">
             没有账号？去注册
          </div>

          <div @click="goResetpassword">
              忘记密码
          </div>
        </div>
      </div>
      <p class="company_info">
        Copyright@2020 Sxreading.com 北京玛峰教育技有限公司版权所有
      </p>
    </div>
  </div>
</template>

<script>
import {Login} from '@/api/login'
export default {
  data() {
    return {
      sendNum: 0,
      showCount: true,
      errorStatus: false,
      count: "",
      warningTxt: "",
      timer: null,
      eyeStatus: "close",
      message: "Hello Vue!",
      focusUserNameStatus: false,
      focusUserPasswordStatus: false,
      focusNickNameStatus: false,
      focusCatpchaStatus: false,
      passwordType: "password",
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
    };
  },
  methods: {
      goRegister() {
          this.$router.push('/register')
      },
      goResetpassword() {
            this.$router.push('/resetPassword')
      },
    registerFc() {
      let r = this.ruleForm;
      if (
        r.mobile.length !== 11 ||
        r.password.length < 6 ||
        r.password.length > 16
      ) {
        this.errorStatus = true;
        this.warningTxt = "请根据提示输入正确的信息";
      } else {
        this.errorStatus = false;



        this.$store.dispatch('LoginByUsername', this.ruleForm).then(res => {
             if (res.data.code == 200) {
              let data = res.data.data;
              if (data.error == 0) {
                this.$router.replace('/index')
              } else {
                this.$message.error(data.message);
              }
            }
        })
       
    
     
      }
    },
    showPassword() {
      if (this.eyeStatus == "open") {
        this.eyeStatus = "close";
        this.passwordType = "password";
      } else {
        this.eyeStatus = "open";
        this.passwordType = "text";
      }
    },
    // sendCaptcha() {
    //   if (this.ruleForm.mobile !== "") {
    //     server
    //       .post("/website/user/captcha", {
    //         mobile: this.ruleForm.mobile,
    //         register_verify: 1,
    //         type: 1,
    //       })
    //       .then((res) => {
    //         if (res.data.code == 200) {
    //           let data = res.data.data;
    //           if (data.error == 0) {
    //             this.countFc();
    //           } else {
    //             this.$message.error(data.message);
    //           }
    //         }
    //       });
    //   } else {
    //     this.$message({
    //       message: "请输入手机号码",
    //       type: "warning",
    //     });
    //     this.$refs["mobile"].focus();
    //   }
    // },
    countFc() {
      this.sendNum = this.sendNum + 1;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCount = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCount = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    clearInput(e) {
      if (e == "mobile") {
        this.ruleForm.mobile = "";
      }

      if (e == "password") {
        this.ruleForm.password = "";
      }
      this.$refs[e].focus();
    },
    userNameInputFocus() {
      this.focusUserNameStatus = true;
    },
    userNameInputBlur() {
      this.focusUserNameStatus = false;
    },
    passwordInputFocus() {
      this.focusUserPasswordStatus = true;
    },
    passwordInputBlur() {
      this.focusUserPasswordStatus = false;
    },
  },
};
</script>

<style scoped>
input {
  outline: none;
}
html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
}
.body_container {
  width: 1200px;
  margin: auto;
}
.body_login_wrapper {
 
  width: 100%;
  height: 100vh;

  background: url(../assets/logo_bg.png) center no-repeat;
  background-size: cover;
}
.body_login_form {
  width: 520px;
  height: auto;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(35, 51, 83, 0.08);
  padding: 40px 50px 61px 50px;
  position: relative;
}
.company_info {
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  margin-top: 25px;
}
.body_login_title {
  font-size: 28px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 50px;
}
.body_login_username {
  margin-bottom: 20px;
}
.login_button_wrap {
  margin-top: 30px;
  margin-bottom: 23px;
}
.login_button_wrap button {
  width: 100%;
}
.register_button {
  width: 100%;
}
.submit_button {
  width: 100%;
}
.login_input_item {
  width: 100%;
  height: 47px;
  background: #f8f9fc;
  border-radius: 2px;
  margin-bottom: 21px;
  border: 1px solid #f8f9fc;
  position: relative;
}
.login_button {
  border: none;
  width: 100%;
  height: 47px;
  background: rgba(253, 208, 0, 1);
  font-size: 14px;
  color: #333333;
  border-radius: 2px;
  cursor: pointer;
}
.showBorder {
  border: 1px solid #4393F9;
}

.login_input_item input {
  width: 80%;
  border: none;
  background: none;
}
.login_input_item_inset {
  width: 100%;
}
.login_input_item_inset_icon {
  width: 20px;
  margin-left: 20px;
  margin-right: 19px;
}
.login_input_item_inset_icon img {
  display: block;
  width: 100%;
}
.login_input_item_clear {
  width: 18px;
  cursor: pointer;
  transition: 0.5s;
  margin-right: 21px;

  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 0px;
}

.login_input_item_clear img {
  display: block;
  width: 100%;
}
.error_message {
  color: #fc605b;
  font-size: 14px;
  position: absolute;
}
.error_message img {
  width: 16px;
  height: 16px;
  display: block;
  margin-right: 8px;
}
.login_button_control_item {
  font-size: 14px;
  color: #26242a;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
  font-size: 14px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cccccc;
  font-size: 14px;
}
.sendCaptcha {
  color: #4393F9;
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
  padding-right: 10px;
}
.sendCaptcha label {
  cursor: pointer;
}
.login {
  color: #4393f9;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}
.eyes_icon {
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}
.eyes_icon img {
  width: 100%;
  display: block;
}
.no_acc{
    color: #4a4a4a;
    font-size: 14px;
}
.no_acc div{
    cursor: pointer;
    color: #4393f9;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
}
.login_logo{
  width: 180px;
  height: 36px;
  position: absolute;
  top: 35px;
  left: 18.55vw;
}
.login_logo img{
  width: 100%;
  display: inline-block;
}
</style>