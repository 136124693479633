<template>
    <div>
         <Header :currentIndex="currentIndex"></Header>
        <div class="body_content">
         <div class="iss_banner_wrapper">
            <div class="iss_banner_inset">
              

                <div class="iss_module_txt_wrap">
                  <div class="iss_module_title">WEB后台管理系统</div>
                  <p>为学校提供⼀站式线上管理平台帮助学校进⾏精细化管理升级</p>
                  <p>从招⽣录取到学⽣毕业，全⾯数据化管理学⽣在校学习与⽣活</p>
               </div>

              
            </div>
        </div>

        <div class="iss_system_module">
            <div class="iss_moudle_banner_iss_bg">
                <img src="@/assets//isssystem.png" alt="">
            </div>
            <div class="iss_system_inset">
                <div  class="flex-wrap-st justify-btw">
                    <div class="iss_system_inset_item">
                        <div class="flex-wrap-st align-ctc iss_system_inset_item_header">
                            <label><img src="@/assets/iss_item_icon001.png" alt=""></label>
                            <h3>教师管理</h3>
                        </div>
                        <div class="iss_system_inset_item_info">
                            分配任课班级、管理职级、查看历史任教记录及成绩。为教师评优提供决策数据支持。
                        </div>
                    </div>
                    <div class="iss_system_inset_item">
                        <div class="flex-wrap-st align-ctc iss_system_inset_item_header">
                            <label><img src="@/assets/iss_item_icon003.png" alt=""></label>
                            <h3>招生管理</h3>
                        </div>
                        <div class="iss_system_inset_item_info">
                            招⽣批次管理——每年可创建多个招⽣批次，便于管理提前招⽣、特⻓招⽣、考试招⽣等多种类型
                        </div>
                    </div>
                    <div class="iss_system_inset_item">
                        <div class="flex-wrap-st align-ctc iss_system_inset_item_header">
                            <label><img src="@/assets/iss_item_icon004.png" alt=""></label>
                            <h3>考试管理</h3>
                        </div>
                        <div class="iss_system_inset_item_info">
                            每次考试分别管理成绩，可以按照班级录⼊成绩。形成班级成绩分析、学⽣成绩曲线等报告。
                        </div>
                    </div>
                    <div class="iss_system_inset_item">
                        <div class="flex-wrap-st align-ctc iss_system_inset_item_header">
                            <label><img src="@/assets/iss_item_icon002.png" alt=""></label>
                            <h3>学生管理</h3>
                        </div>
                        <div class="iss_system_inset_item_info">
                            灵活管理学⽣的分班、换班、寝室床位；学⽣奖惩记录、⼲部任职记录、⼊团⼊队记录
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="iss_system_mobile_module">
            <div class="iss_system_mobile_inset">
                <h3 class="iss_system_mobile_module_title">家长.移动端</h3>
                <p class="iss_system_mobile_sub_title">公众号H5功能文案简单介绍公众号H5功能文案简单介绍公众号H5功能文</p>
                <p class="iss_system_mobile_sub_title">公众号H5功能文案简单介绍公众号H5功能文案简单介绍</p>

                <div class="iss_system_mobile_bg_wrap">
                   
                </div>


              
                
            </div>
        </div>
        

       <Footer></Footer>
       </div>
    </div>
</template>


<script>
import Footer from "../components/footer";

import Header from "../components/header";
export default {
  components: {
    Footer,
    Header,
  },
  data () {
      return {
          loginStatus: true,
          currentIndex: 3
      }
  },
 
}
</script>

<style scoped>
.body_content{
    margin-top: -80px;
}
.iss_banner_wrapper{
    background: url(../assets/iss_banner.png) center no-repeat;
    background-size: cover;
    height: 800px;
    width: 100%;
    overflow: hidden;
}
.iss_banner_inset{
    width: 1200px;
    margin: auto;
}
.iss_module_title{
    font-size: 48px;
    color: #ffffff;
    line-height: 62px;
    text-align: center;
    margin-top: 161px;
    font-weight: normal;
    margin-bottom: 15px;
}
.iss_module_txt_wrap p{
    margin: 0;
    padding: 0;
    color: #D2D2D2;
    font-size: 18px;
    text-align: center;
    line-height: 34px;
}
.iss_moudle_banner_iss_bg{
    width: 880px;
    height: auto;
    position: absolute;
    top: -246px;
    left: 50%;
    margin-left: -440px;

}
.iss_moudle_banner_iss_bg img{
    width: 100%;
    display: block;
}
.iss_system_module{
    width: 100%;
    height: auto;
    border-top:1px solid darkblue;
    position: relative;
}
.iss_system_inset{
    width: 1200px;
    margin: auto;
    height: auto;
    margin-top: 497px;
    margin-bottom: 229px;
    position: relative;

}
.iss_system_inset_item{
    width: 230px;
}
.iss_system_inset_item label{
    width: 32px;
    display: inline-block;
    margin-right: 25px;
}
.iss_system_inset_item label img{
    width: 100%;
    display: block;
}
.iss_system_inset_item h3{
    font-size: 24px;
    color: #2C2E30;
    font-weight: normal;
    margin: 0;
}
.iss_system_inset_item_header{
    margin-bottom: 19px;
}
.iss_system_inset_item_info{
    font-size: 16px;
    color: #333333;
    line-height: 28px;
    text-align: justify;
}
.iss_system_mobile_module{
    width: 100%;
    height: auto;
    background: #F9F9F9;
    padding: 81px 0 145px 0;
}
.iss_system_mobile_module_title{
    font-size: 34px;
    line-height: 60px;
    margin-bottom: 13px;
    color: #0B162B;
    text-align: center;
    font-weight: normal;
    
}
.iss_system_mobile_inset{
    width: 1200px;
    margin: auto;
    height: auto
}
.iss_system_mobile_sub_title{
    font-size: 18px;
    color: #0B162B;
    line-height: 30px;
    margin: 0;
    text-align: center;
    opacity: .5;
}
.iss_system_mobile_bg_wrap{
    width:1060px;
    margin: auto;
    margin-top: 97px;
    background: url('../assets/iss_system_mobile_bg.png') center no-repeat;
    background-size: cover;
    height: 616px;
}
.iss_system_mobile_bg_wrap img{
    width: 100%;
    display: block;
}
</style>