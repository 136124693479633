import request from '../utils/request'
export function signUp (t1, t2, t3, t4, t5, t6  ) {
    const data = {
        name: t1,
        from_school: t2,
        mobile1: t3,
        province_id: t4,
        city_id: t5,
        district_id: t6
        
    }
    return request({
      url: `/website/user/sign/up/submit`,
      method: 'post',
      data
    })
  }
  
