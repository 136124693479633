

import request from '../utils/request'

export function websiteProvince () {
    return request({
      url: `/website/province`,
      method: 'get'
    })
  }

export function provonceChildren(t1, t2) {
    const data = {
        parent_id: t1,
        level: t2
    }
    return request({
        url: `/website/province/children`,
        method: 'post',
        data
    })
}
  