
import request from '../utils/request'

export function verifyPhone (t1, t2) {
    const data = {
        mobile: t1,
        captcha: t2
    }
    return request({
      url: `/website/user/password/verify/phone`,
      method: 'post',
      data
    })
  }

  export function passwordReset(t1, t2) {
      const data = {
          password: t2
      }
    return request({
        url: `/website/user/password/reset/${t1}`,
        method: 'post',
        data
      })
  }
  
  