
import request from '../utils/request'

export function contactUs (t1) {
 
    return request({
      url: `/website/web/contactUs`,
      method: 'post'
    })
  }
  